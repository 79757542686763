<template>
    <Field
        is="custom"
        v-bind="$attrs"
        :label="label"
        :error="error"
        :isLoading="isLoading"
        :helperText="helperText"
        :disabled="disabled"
    >
        <div class="dropdown is-width-100" :class="{'is-active': isOpen}">
            <div class="dropdown-trigger is-width-100">
                <input
                    v-bind="$attrs"
                    ref="baseInput"
                    type="text"
                    class="input is-width-100 is-primary"
                    :class="{
                        'is-danger': error,
                    }"
                    @focus="isOpen = true"
                    @blur="handleBlur"
                    @input="handleTyping"
                    :value="(model?.name ?? displayValue) ?? ''"
                    aria-haspopup="true" aria-controls="dropdown-menu"
                    :disabled="disabled"
                />
            </div>
            <div class="dropdown-menu is-width-100" id="dropdown-menu" role="menu">
                <div class="dropdown-content is-width-100">
                    <a
                        v-if="isLoading"
                        class="dropdown-item is-width-100 cursor-default"
                    >
                        <p class="has-text-grey-light">
                            Loading...
                        </p>
                    </a>
                    <a
                        v-else-if="(props.options?.length ?? 0) === 0"
                        class="dropdown-item is-width-100 cursor-default"
                    >
                        <p class="has-text-grey-light">
                            No results found.
                        </p>
                    </a>
                    <a
                        v-else
                        v-for="option in props.options"
                        :key="option.id"
                        class="dropdown-item is-width-100"
                        @mousedown.prevent="handleOptionSelect(option)"
                    >
                        <slot name="option" :option="option">
                            <div>{{ option.name }}</div>
                        </slot>
                    </a>
                </div>
            </div>
        </div>

    </Field>

</template>

<script setup lang="ts">
import Field from "@/Components/common/Field.vue";
import {nextTick, ref, watch} from "vue";
import {AutocompleteOption} from "@/Components/common/Autocomplete";

const model = ref<AutocompleteOption|null>(null);
const displayValue = ref<null|string>(null);

const props = withDefaults(defineProps<{
    modelValue: AutocompleteOption|null,
    options: AutocompleteOption[],
    label: string,
    error?: string,
    isLoading?: boolean,
    helperText?: string,
    disabled?: boolean,
}>(), {
    isLoading: false,
});

watch(() => props.modelValue, (value) => {
    model.value = value;
});

const emit = defineEmits<{
    (e: 'update:modelValue', value: AutocompleteOption|null): void;
    (e: 'query', query: string): void;
}>();

const slots = defineSlots<{
    option?: (props: { option: AutocompleteOption }) => any;
}>();

const isOpen = ref(false);
const baseInput = ref<HTMLInputElement|null>(null);

function handleOptionSelect(option: AutocompleteOption) {
    emit('update:modelValue', option);
    baseInput?.value.blur();
    isOpen.value = false;
}

function handleBlur() {
    isOpen.value = false;
    displayValue.value = null;
}

function handleTyping(e: InputEvent) {
    emit('update:modelValue', null);
    if ((e.target as HTMLInputElement).value) {
        displayValue.value = (e.target as HTMLInputElement).value;
    }

    nextTick(() => {
        emit('query', (e.target as HTMLInputElement).value);
    });
}

</script>

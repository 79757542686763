import { defineStore } from 'pinia';
import type { Item } from '@/Types/Item';
import type { Shop } from '@/Types/Shop';
import type { Sort } from '@/Types/Sort';
import { router, usePage } from '@inertiajs/vue3';
import { Goal } from '@/Types/Goal';
import { ShopTheme } from '@/Types/ShopTheme';

export const useShopStore = defineStore('shop', {
    state: () => ({
        items: [] as Item[],
        defaultSortItems: [] as Item[],
        sort: 'category' as Sort,
        visibility: 'private' as 'public' | 'unlisted' | 'private',
        loading: true,
        bannerUrl: '',
        logoUrl: '',
        id: -1,
        name: '',
        description: '',
        slug: '',
        categories: [] as string[],
        goals: [] as Goal[],
        dateOpen: null as null|string,
        dateClose: null as null|string,
        theme: {
            color: '' as null|string,
            image: '' as null|string,
        } as ShopTheme
    }),
    getters: {
        getByID: (state) => (id: number): Promise<Item> => {
            return new Promise((resolve, reject) => {
                let cachedItem = state.items.find((item) => item.id === id);
                if (cachedItem) {
                    return resolve(cachedItem);
                }

                router.reload({
                    only: ['item'],
                    onStart: () => {
                        state.loading = true;
                    },
                    onSuccess: () => {
                        const page = usePage();
                        const item = (page.props as any).item;
                        state.loading = false;
                        resolve(item);
                    },
                    onError: (e) => {
                        state.loading = false;
                        reject(e);
                    }
                })
            });

        },
        getSortOptions: (state) => (): { value: Sort, text: String }[] => {
            return [
                { value: 'category', text: (state.categories && state.categories.length > 0 ? 'Category' : 'Default') },
                { value: 'price-low-to-high', text: 'Price Low to High' },
                { value: 'price-high-to-low', text: 'Price High to Low' },
                { value: 'alphabetical-a-to-z', text: 'Alphabetical A to Z' },
                { value: 'alphabetical-z-to-a', text: 'Alphabetical Z to A' }
            ];
        },
        getItemsPerCategory: (state) => () => {
            if (!state.categories || state.categories.length === 0) return { '': state.items }

            const itemsPerCategory: Record<string, Item[]> = {};
            itemsPerCategory[''] = state.items.filter(item => item.category === null);
            for (const category of state.categories) {
                itemsPerCategory[category] = state.items.filter(item => item.category === category);
            }
            return itemsPerCategory;
        }
    },
    actions: {
        async initialize(newShowId: number) {
            if (!newShowId || newShowId < 1) return;
            if (this.id === newShowId) return;

            this.items = [];
            this.defaultSortItems = [];
            this.name = '';
            this.description = '';
            this.slug = '';
            this.categories = [];
            this.visibility = 'private';
            this.bannerUrl = '';
            this.logoUrl = '';
            this.dateClose = null;
            this.dateOpen = null;
            this.theme = {
                color: "",
                image: ""
            }
            this.goals = [];

            this.id = newShowId;
            await this.refresh();
            return;
        },
        async refresh() {
            this.loading = true;

            const shop = await getShop();
            const newItems = await refreshItems(shop.slug);

            this.name = shop.name;
            this.description = shop.description;
            this.slug = shop.slug;
            this.items = newItems;
            this.categories = shop.categories;
            this.visibility = shop.visibility;
            this.defaultSortItems = [...this.items];
            this.bannerUrl = shop.bannerUrl;
            this.logoUrl = shop.logoUrl;
            this.dateClose = shop.dateClose;
            this.dateOpen = shop.dateOpen;
            this.theme = {
                color: shop.theme?.color ?? null,
                image: shop.theme?.image ?? null,
            }
            this.goals = shop.goals ?? [];

            this.loading = false;
            return;
        },
        deleteItem(id: number) {
            const index = this.items.findIndex((item) => item.id === id);
            if (index === -1) return;

            this.items.splice(index, 1);
            this.defaultSortItems = this.defaultSortItems.filter((item) => item.id !== id);
        },
        changeSort(sort: Sort) {
            this.sort = sort;

            switch (sort) {
                case "category":
                    this.items = [...this.defaultSortItems];
                    break;
                case "price-high-to-low":
                    this.items = [...this.defaultSortItems].sort((a, b) => b.price - a.price);
                    break;
                case "price-low-to-high":
                    this.items = [...this.defaultSortItems].sort((a, b) => a.price - b.price);
                    break;
                case "alphabetical-a-to-z":
                    this.items = [...this.defaultSortItems].sort((a, b) => a.title.localeCompare(b.title));
                    break;
                case "alphabetical-z-to-a":
                    this.items = [...this.defaultSortItems].sort((a, b) => b.title.localeCompare(a.title));
                    break;
            }
        },
    }
});

async function refreshItems(shopSlug: string): Promise<Item[]> {
    const response = await fetch(route('shop.items.fetch', {shop: shopSlug}));
    const {status, payload} = await response.json();
    if (status !== 'OK') {
        throw new Error(payload);
    }

    return payload;
}

async function getShop(): Promise<Shop> {
    return new Promise((resolve, reject) => {
        const page = usePage();
        router.reload({
            only: ['shop'],
            onSuccess: () => {
                resolve((page.props as any).shop);
            },
            onError: (e) => {
                reject(e);
            }
        });
    });
}

<template>
    <div>
        <div class="img-selector-image-wrapper"
            :class="{ 'multiple-img': hasMultipleImages && !detailed, 'multiple-img-detailed': hasMultipleImages && detailed }">

            <div ref="imgElement" class="img-anim-wrapper">

                <template v-if="detailed">
                    <div ref="imgPan" class="img-pan">
                        <LoadingImg class="img-selector-image" :src="srcArr[renderedImgIndex]" :alt="alt" />
                    </div>
                    <div v-if="label && label.length > 0" class="variant-label tag is-primary">{{ label }}</div>
                </template>

                <template v-else>
                    <LoadingImg class="img-selector-image" :src="srcArr[renderedImgIndex]" :alt="alt" loading="lazy" />
                </template>
            </div>

            <button v-if="hasMultipleImages" @click="prevImg" class="left is-height-100">
                <span class="icon has-text-link">
                    <Icon :icon="mdiChevronLeft" />
                </span>
            </button>

            <button v-if="hasMultipleImages" @click="nextImg" class="right is-height-100">
                <span class="icon has-text-link">
                    <Icon :icon="mdiChevronRight" />
                </span>
            </button>

        </div>

        <div v-if="hasMultipleImages" class="is-flex is-justify-content-center mt-2">

            <template v-if="detailed">
                <div class="is-flex is-justify-content-center has-gap-small is-flex-wrap-wrap is-height-fit">
                    <LoadingImg v-for="img in srcArr" class="thumbnail is-clickable" :key="img" :src="img" :alt="alt"
                        @click="setImg(srcArr.indexOf(img), $event)" :class="{
                            'selected': imgIndex === srcArr.indexOf(img),
                            'dim': relevantIndices.length > 0 && !relevantIndices.includes(srcArr.indexOf(img))
                        }" />
                </div>
            </template>

            <template v-else>
                <span v-if="props.srcArr.length < 7" v-for="img in srcArr" class="icon has-text-link is-clickable"
                    @click="setImg(srcArr.indexOf(img), $event)">
                    <Icon :icon="mdiCircle" class="icon" :class="[imgIndex === srcArr.indexOf(img) ? '' : 'small']" />
                </span>
                <span v-else class="is-justify-content-center is-clickable is-unselectable tag is-primary is-medium"
                    @click="nextImg">
                    {{ imgIndex + 1 }}/{{ props.srcArr.length }}
                </span>
            </template>

        </div>
    </div>
</template>

<script setup lang="ts">
import { PropType, computed, ref, watch, onMounted, onUnmounted } from 'vue';
import { mdiChevronLeft, mdiChevronRight, mdiCircle } from '@mdi/js';
import gsap from 'gsap';
import Icon from "@/Components/Icon.vue";
import LoadingImg from './LoadingImg.vue';

const tl = gsap.timeline();

const props = defineProps({
    srcArr: {
        type: Array as PropType<string[]>,
        required: true
    },
    alt: {
        type: String,
        required: false
    },
    detailed: {
        type: Boolean,
        required: false,
        default: false
    },
    labels: {
        type: Object as PropType<Record<number, string>>,
        required: false
    },
    relevantIndices: {
        type: Array as PropType<number[]>,
        required: false,
        default: []
    }
});

const hasMultipleImages = computed(() => {
    return props.srcArr.length > 1
});

const imgElement = ref<HTMLImageElement | null>(null);
const imgPan = ref<HTMLDivElement | null>(null);
const imgIndex = defineModel<number>({ type: Number, default: 0 });
const renderedImgIndex = ref(0);
const touchStartX = ref(0);
const isZoomed = ref(false);

const label = computed(() => props.labels?.[renderedImgIndex.value]);

const zoomTl = gsap.timeline();

let isTouchScrolling = false;

function nextImg(e: MouseEvent | TouchEvent | null = null) {
    e?.preventDefault();
    const index = (imgIndex.value + 1) % props.srcArr.length;
    imgIndex.value = index;
    changeImage('left', index);
}

function prevImg(e: MouseEvent | TouchEvent | null = null) {
    e?.preventDefault();
    const index = (imgIndex.value - 1 + props.srcArr.length) % props.srcArr.length;
    imgIndex.value = index;
    changeImage('right', index);
}

function setImg(index: number, e: MouseEvent | TouchEvent | null = null) {
    e?.preventDefault();
    if (index === imgIndex.value) return;
    imgIndex.value = index;
    changeImage(index > renderedImgIndex.value ? 'left' : 'right', index);
}

function changeImage(direction: 'left' | 'right', index: number) {
    tl.clear();

    if (!window.matchMedia('(prefers-reduced-motion: reduce)').matches) {
        tl.to(imgElement.value, {
            duration: .15,
            x: direction === 'right' ? '100%' : '-100%',
            ease: 'power2.in',
            onComplete: () => {
                renderedImgIndex.value = index;
            },
        })
            .set(imgElement.value, {
                x: direction === 'right' ? '-100%' : '100%',
                width: '100%'
            })
            .to(imgElement.value, {
                duration: .15,
                ease: 'power2.out',
                x: 0
            }, "+=.1");
    } else {
        renderedImgIndex.value = index;
    }
}

onMounted(() => {
    if (imgPan.value) {
        imgPan.value.addEventListener('mouseenter', imgPanMouseEnter);
        imgPan.value?.addEventListener('mousemove', imgPanMouseMove);
        imgPan.value?.addEventListener('mouseleave', () => {
            isZoomed.value = false;
        });
    }

    if (!hasMultipleImages.value) return;

    imgElement.value?.addEventListener('touchstart', handleTouchStart);
    imgElement.value?.addEventListener('touchend', handleTouchEnd);
    imgElement.value?.addEventListener('touchcancel', handleTouchEnd);
    imgElement.value?.addEventListener('touchmove', handleTouchMove);
});

onUnmounted(() => {
    imgPan.value?.removeEventListener('mouseenter', imgPanMouseEnter);
    imgPan.value?.removeEventListener('mousemove', imgPanMouseMove);
    imgPan.value?.removeEventListener('mouseleave', () => {
        isZoomed.value = false;
    });

    imgElement.value?.removeEventListener('touchstart', handleTouchStart);
    imgElement.value?.removeEventListener('touchend', handleTouchEnd);
    imgElement.value?.removeEventListener('touchcancel', handleTouchEnd);
    imgElement.value?.removeEventListener('touchmove', handleTouchMove);
});

watch(isZoomed, (newVal) => {
    zoomTl.clear();

    if (newVal) {
        if (!window.matchMedia('(prefers-reduced-motion: reduce)').matches) {
            zoomTl.to(imgPan.value, {
                duration: .3,
                scale: 1.8,
                ease: 'power3.out'
            })
        } else {
            zoomTl.set(imgPan.value, {
                scale: 1.8
            })
        }
    } else {
        if (!window.matchMedia('(prefers-reduced-motion: reduce)').matches) {
            zoomTl.to(imgPan.value, {
                duration: .1,
                scale: 1,
                "--origin-x": "50%",
                "--origin-y": "50%",
                ease: 'power2.out'
            });
        } else {
            zoomTl.set(imgPan.value, {
                scale: 1,
                "--origin-x": "50%",
                "--origin-y": "50%"
            });
        }
    }
});

function imgPanMouseEnter(e: any) {
    if (e.sourceCapabilities?.firesTouchEvents) return;
    isZoomed.value = true;
}

function imgPanMouseMove(e: MouseEvent) {
    try {
        const x = (e.offsetX / imgPan.value.clientWidth) * 100;
        const y = (e.offsetY / imgPan.value.clientHeight) * 100;
        imgPan.value.style.setProperty('--origin-x', x.toString() + '%');
        imgPan.value.style.setProperty('--origin-y', y.toString() + '%');
    } catch (e) {
        return;
    }
}

function handleTouchStart(e: TouchEvent) {
    touchStartX.value = e.touches[0].clientX;
}

function handleTouchMove(e: TouchEvent) {
    if (isTouchScrolling) {
        gsap.set(imgElement.value, {
            x: e.touches[0].clientX - touchStartX.value
        });
    } else {
        isTouchScrolling = true;
        gsap.to(imgElement.value, {
            x: e.touches[0].clientX - touchStartX.value,
            duration: .05,
            ease: 'power2.in'
        });
    }
}

function handleTouchEnd(e: TouchEvent) {
    const touchEndX = e.changedTouches[0].clientX;
    const diff = touchEndX - touchStartX.value;
    const target = 120;
    isTouchScrolling = false;

    if (diff > target) {
        prevImg();
    } else if (diff < -target) {
        nextImg();
    } else {
        gsap.to(imgElement.value, {
            x: 0,
            duration: .15,
            ease: 'power2.inOut'
        });
    }
}
</script>

<style lang="scss">
@use "bulma/sass/utilities/mixins";

.img-selector-image-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: #FFFFFF10;
    border-radius: .5em;
    position: relative;
    aspect-ratio: 1 / 1;

    .img-anim-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
    }

    .img-selector-image {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
        border-radius: .5em;
    }

    .img-selector-image[data-error="1"] {
        width: auto;
        height: auto;
        place-self: center;
        margin: auto;
    }

    button {
        position: absolute;
        width: 3em;
        top: 0;
        filter: drop-shadow(0 0 .1em black) drop-shadow(0 0 .3em #00000070);
    }

    button:hover {
        background-color: #FFFFFF20;
    }

    button.left {
        left: 0;
    }

    button.right {
        right: 0;
    }
}

.img-selector-image-wrapper.multiple-img {
    height: calc(100% - 2em);
}

.icon {
    transition: transform .2s;
}

.icon.small {
    transform: scale(.5);
}

.thumbnail {
    width: min(5em, 15vw) !important;
    height: min(5em, 15vw) !important;
    border-radius: .5em;
    border: 0 solid var(--bulma-warning);
    transition: border-width .05s;
    transition: opacity .2s;
}

.thumbnail.selected,
.thumbnail:hover {
    border-width: .15em;
}

.thumbnail.dim {
    opacity: .4;
    filter: brightness(.5);
}

.thumbnail.dim.selected {
    opacity: .75;
    filter: brightness(.75);
}

.variant-label {
    position: absolute;
    left: .8em;
    bottom: .8em;
    box-shadow: 0 0.5rem 0.75rem 0 rgba(0, 0, 0, 0.37);
    font-size: 1.25rem !important;
}

@include mixins.touch {
    .variant-label {
        font-size: 1rem !important;
    }
}

.img-pan {
    width: 100%;
    cursor: zoom-in;
    --origin-x: 50%;
    --origin-y: 50%;
    transform-origin: var(--origin-x) var(--origin-y);
}
</style>

<template>

    <Head>
        <title v-if="!title">Level1 Shop</title>
        <title v-else>{{ titleLimit }} - Level1 Shop</title>

        <meta v-if="description" name="description" :content="descLimit" />
        <meta v-if="description" name="og:description" :content="descLimit" />

        <meta v-if="image" name="og:image" :content="image" />

        <meta v-if="url" name="og:url" :content="url" />
        <meta v-else name="og:url" content="https://shop.level1.tv/" />

        <meta name="og:site_name" content="Level1 Shop"/>
    </Head>
</template>

<script lang="ts" setup>
import { Head } from '@inertiajs/vue3';
import { computed } from 'vue';

const props = defineProps<{
    title?: string;
    description?: string;
    image?: string;
    url?: string;
}>();

const titleLimit = computed(() => {
    const limit = 25;
    if (props.title) {
        return props.title.length > limit ? `${props.title.slice(0, limit)}...` : props.title;
    }
    return '';
});

const descLimit = computed(() => {
    const limit = 150;
    if (props.description) {
        return props.description.length > limit ? `${props.description.slice(0, limit - 3)}...` : props.description;
    }
    return '';
});
</script>
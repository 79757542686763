<template>
    <div class="is-flex is-flex-direction-column is-width-100">
        <PageHead title="Terms of Service" />

        <div name="termly-embed" data-id="9cb5894a-6252-4cec-a3c1-840845d03f79" data-type="iframe"></div>
    </div>
</template>

<script setup lang="ts">
import Base from "@/Layouts/Base.vue";
import Default from "@/Layouts/Default.vue";
import {onMounted} from "vue";
import PageHead from "@/Components/PageHead.vue";

defineOptions({
    layout: [Base, Default]
});

onMounted(() => {
    const script = document.createElement("script");
    script.src = "https://app.termly.io/embed-policy.min.js";
    script.async = true;
    document.body.appendChild(script);
});
</script>
<template>
    <div class="columns is-multiline">
        <template v-for="admin in admins">
            <div class="box is-width-100 is-flex is-align-items-baseline has-gap-large">
                <button class="button is-outlined is-danger" @click="removeUser(admin.id)">
                    <Icon :icon="mdiTrashCanOutline" />
                </button>
                <div class="is-size-4">
                    {{ admin.firstName }} {{ admin.lastName }}
                </div>
                <div class="is-size-6">
                    {{ admin.email }}
                </div>
            </div>
        </template>

        <div class="column is-12">Add Admin</div>

        <div class="column is-12">
            <Field class="column" v-model="addUserEmail" label="Email" />
            <button v-if="!addingUser" class="button is-primary" @click="addUser">Add</button>
            <button v-else class="button is-primary is-loading">Add</button>

        </div>

        <article v-if="error.length > 0" class="message is-danger is-width-100">
            <div class="message-body">
                {{ error }}
            </div>
        </article>

        <article v-if="confirmation.length > 0" class="message is-success is-width-100">
            <div class="message-body">
                {{ confirmation }}
            </div>
        </article>
    </div>
</template>

<script setup lang="ts">
import { type User } from '@/Types/User';
import Field from '../common/Field.vue';
import { mdiTrashCanOutline } from '@mdi/js';
import { onMounted, ref } from 'vue';

const props = defineProps({
    shopId: {
        type: Number,
        required: true
    }
})

const addUserEmail = ref('');
const admins = ref<User[]>([]);
const addingUser = ref(false);
const error = ref('');
const confirmation = ref('');

onMounted(async () => {
    await refreshAdmins();
});

async function refreshAdmins() {
    admins.value = await $fetch(`/api/shops/${props.shopId}/admins`, {
        method: 'GET'
    }) as User[];
}

async function addUser() {
    addingUser.value = true;
    confirmation.value = '';

    let user: User;
    try {
        user = await $fetch(`/api/users/email/${addUserEmail.value}`, {
            method: 'GET'
        }) as User;
    } catch (e) {
        error.value = 'User not found';
        addingUser.value = false;
        return;
    }

    let res;
    try {
        res = await $fetch(`/api/shops/${props.shopId}/admin/${user.id}`, {
            method: 'PUT'
        });
    } catch (e) {
        error.value = 'Internal error';
        addingUser.value = false;
        return;
    }

    confirmation.value = `User '${user.firstName} ${user.lastName}' added as admin`;
    error.value = '';
    addingUser.value = false;
    await refreshAdmins();
}

async function removeUser(userId: number) {
    const res = await $fetch(`/api/shops/${props.shopId}/admin/${userId}`, {
        method: 'DELETE'
    });
    admins.value = res as User[];
}

</script>

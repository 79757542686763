<template>
    <div class="banner has-text-primary is-size-4">
        <div>
            <slot></slot>
        </div>
        <div class="background has-background-warning">

        </div>
    </div>
</template>

<script lang="ts" setup>

</script>

<style lang="scss" scoped>
.banner {
    width: 100%;
    padding: 3rem 8.5%;
    position: relative;

    .background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
}
</style>
<template>
    <div class="field">
        <label v-if="props.label && props.is !== 'checkbox'" class="label">{{ props.label }}</label>
        <div
            class="control"
            :class="{
                'is-loading': props.isLoading,
                'has-icons-left': $slots.iconLeft,
                'has-icons-right': $slots.iconRight,
            }"
        >
            <template v-if="props.is === 'textarea'">
                <textarea
                    v-bind="$attrs"
                    :class="{
                        'is-primary': !props.error,
                        'is-danger': props.error,
                        'is-loading': props.isLoading,
                    }"
                    class="textarea"
                    :value="internalValue as string"
                    :placeholder="props.placeholder"
                    :disabled="props.disabled"
                    @input="(e: Event) => emit('update:modelValue', (e.target as any)?.value)"
                />
            </template>

            <template v-else-if="props.is === 'select'">
                <div class="select">
                    <select
                        v-bind="$attrs"
                        class="select is-width-100"
                        :class="{
                            'is-primary': !props.error,
                            'is-danger': props.error,
                            'is-loading': props.isLoading,
                        }"
                        :value="internalValue"
                        :disabled="props.disabled"
                        @input="(e: Event) => emit('update:modelValue', (e.target as any)?.value)"
                    >
                        <slot />
                    </select>
                </div>
            </template>

            <template v-else-if="props.is === 'custom'">
                <slot />
            </template>

            <template v-else-if="props.is === 'checkbox'">
                <label class="checkbox">
                    <input
                        v-bind="$attrs"
                        type="checkbox"
                        :checked="internalValue"
                        :disabled="props.disabled"
                        @input="(e: Event) => emit('update:modelValue', !internalValue)"
                    />
                    {{ props.label }}
                </label>
            </template>

            <component
                v-else
                :is="props.is ?? 'input'"
                v-bind="$attrs"
                class="input"
                :class="{
                    'is-primary': !props.error,
                    'is-danger': props.error,
                    'is-loading': props.isLoading,
                }"
                :type="props.type || 'text'"
                :value="internalValue"
                :placeholder="props.placeholder"
                :disabled="props.disabled"
                @input="(e: Event) => emit('update:modelValue', (e.target as any)?.value)"
            />


            <span class="icon is-small is-left" v-if="$slots.iconLeft">
                <slot name="iconLeft" />
            </span>

            <span class="icon is-small is-right" v-if="$slots.iconRight">
                <slot name="iconRight" />
            </span>
        </div>

        <template v-if="props.error || props.helperText">
            <p class="help is-danger" v-if="props.error">{{ props.error }}</p>
            <p class="help" v-else>{{ props.helperText }}</p>
        </template>
    </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';

const props = defineProps<{
    is?: string;
    label?: string;
    modelValue?: string | boolean;
    helperText?: string;
    placeholder?: string;
    isLoading?: boolean;
    error?: string;
    disabled?: boolean;
    type?: 'text' | 'password' | 'email' | 'tel' | 'number' | 'file' | 'color';
}>();

const internalValue = ref<string | boolean | undefined>(props.modelValue);

watch(() => props.modelValue, () => {
    internalValue.value = props.modelValue;
});

const emit = defineEmits<{
    (e: 'update:modelValue', value?: string | boolean): void;
}>();



</script>

<template>
    <div class="is-flex-desktop is-flex-direction-row-reverse has-gap-large is-width-100">

        <PageHead title="Checkout" />

        <div class="block" style="min-width: 22rem;">
            <div class="box is-height-fit is-width-100">
                <div class="box-title is-flex is-align-items-center has-gap-small pb-1">
                    <Icon :icon="mdiCart" /> Cart
                </div>

                <div class="is-flex is-flex-direction-column has-gap-medium">
                    <ShopCartItem v-for="(item, itemIndex) in props.cartItems" :key="item.item.id" :cart-item="item"
                        :index="itemIndex" :editable="false" :minimize="true" />
                </div>
            </div>
        </div>

        <div class="is-flex-grow-1">
            <div class="has-background-primary has-radius-large py-5 has-width-fill is-height-100">
                <div id="checkout" />
            </div>
        </div>

    </div>
</template>

<script setup lang="ts">
import Base from "@/Layouts/Base.vue";
import Default from "@/Layouts/Default.vue";
import ShopCartItem from "@/Components/ShopCartItem.vue";
import { CartItem } from "@/Types/CartItem";
import { onMounted, onUnmounted, ref } from "vue";
import { usePaymentStore } from "@/Stores/PaymentStore";
import { StripeEmbeddedCheckout } from "@stripe/stripe-js";
import { router } from "@inertiajs/vue3";
import { useCartStore } from "@/Stores/CartStore";
import { mdiCart } from "@mdi/js";
import Icon from "@/Components/Icon.vue";
import PageHead from "@/Components/PageHead.vue";

defineOptions({
    layout: [Base, Default],
});

const payment = usePaymentStore();

const props = defineProps<{
    cartItems: CartItem[];
    sessionId: string;
    clientSecret: string;
}>();

const cartStore = useCartStore();

const checkout = ref<StripeEmbeddedCheckout | null>(null);

onMounted(async () => {
    await payment.initialize();
    checkout.value = await payment.stripe.initEmbeddedCheckout({
        clientSecret: props.clientSecret,
        onComplete() {
            cartStore.$reset();
            router.get(route('checkout.confirm') + '?session_id=' + props.sessionId);
        },
    });

    checkout.value.mount('#checkout');
});

onUnmounted(() => {
    checkout.value?.destroy();
    checkout.value = null;
});

</script>
<template>
    <div class="price-tag">
        <svg width="34" height="64" viewBox="0 0 34 64"  xmlns="http://www.w3.org/2000/svg" ref="svg">
            <g clip-path="url(#clip0_3_2)">
                <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M2.63604 25.636L25.9289 2.34314C27.4292 0.842852 29.4641 0 31.5858 0H34V64H31.5858C29.4641 64 27.4292 63.1571 25.9289 61.6569L2.63604 38.364C-0.878683 34.8492 -0.878676 29.1508 2.63604 25.636ZM15.201 40C19.6193 40 23.201 36.4183 23.201 32C23.201 27.5817 19.6193 24 15.201 24C10.7827 24 7.20102 27.5817 7.20102 32C7.20102 36.4183 10.7827 40 15.201 40Z"
                     />
            </g>
            <defs>
                <clipPath id="clip0_3_2">
                    <rect width="34" height="64"  />
                </clipPath>
            </defs>
        </svg>

        <div class="slot-wrapper py-1 pr-3 pl-2" ref="slotWrapper">
            <slot />
        </div>
    </div>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, ref } from 'vue';

const slotWrapper = ref<HTMLElement | null>(null);
const svg = ref<SVGElement | null>(null);
const resizeObserver = new ResizeObserver(() => {
    if (!slotWrapper.value || !svg.value) return;

    const box = slotWrapper.value?.getBoundingClientRect();
    svg.value.style.height = `${box.height}px`;
});

onMounted(() => {
    resizeObserver.observe(slotWrapper.value!);
});

onUnmounted(() => {
    resizeObserver.disconnect();
});

</script>

<style lang="scss" scoped>
.price-tag {
    display: flex;
    align-items: center;

    .slot-wrapper {
        background: var(--bulma-primary);
        border-radius: 0 .5rem .5rem 0;
        margin-left: -1px;
    }

    svg {
        fill: var(--bulma-primary);
        aspect-ratio: 34 / 64;
    }
}
</style>
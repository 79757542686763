<template>
    <Dialog
        :open="previousAreOpen"
        @close="previousAreOpen = false"
        title="Previous Exports"
    >
        <template v-if="(props.digitalFulfillmentReport?.previousExports?.length ?? 0) > 0">
            <div
                v-for="thisExport in props.digitalFulfillmentReport.previousExports"
                :key="thisExport.id"
                class="box"
            >
                <div class="columns">
                    <div class="column">
                        <div>
                            {{ dayjs(thisExport.dateCreated).format('MMM. Do, YYYY [@] h:mm:a z') }}
                        </div>
                        <div>
                            Exported By: {{ thisExport.user?.firstName }} {{ thisExport.user?.lastName }}
                        </div>
                    </div>
                    <div class="column is-narrow">
                        <button
                            class="button is-small is-primary"
                            @click="emit('export', thisExport.id)"
                        >
                            Re-Export
                        </button>
                    </div>
                </div>
            </div>
        </template>
        <div v-else>
            There are no previous exports.
        </div>
    </Dialog>

    <div class="box" v-if="props.digitalFulfillmentReport">
        <div class="is-flex is-justify-content-space-between is-align-items-flex-start">
            <div class="box-title">Digital Fulfillment Report</div>
            <div class="buttons">
                <button
                    class="button is-primary is-small"
                    :class="{ 'is-loading': isExporting }"
                    :disabled="isExporting"
                    @click="emit('export')"
                >
                    Export
                </button>

                <button
                    class="button is-warning is-outlined is-small"
                    @click="previousAreOpen = true"
                >
                    Show Previous Exports
                </button>
            </div>
        </div>

        <div
            class="table-container"
            style="max-height: 80vh;"
        >
            <table
                v-if="(props.digitalFulfillmentReport?.notExported?.length ?? 0) > 0"
                class="table is-width-100 is-bordered is-hoverable"
            >
                <thead class="has-background-primary-25">
                    <tr>
                        <th class="is-sticky-header has-background-primary-25">Order ID</th>
                        <th class="is-sticky-header has-background-primary-25">Fulfillment Status</th>
                        <th class="is-sticky-header has-background-primary-25">Name</th>
                        <th class="is-sticky-header has-background-primary-25">Price</th>
                        <th class="is-sticky-header has-background-primary-25">Quantity</th>
                        <th class="is-sticky-header has-background-primary-25">Date Created</th>
                    </tr>
                </thead>
                <tbody class="has-background-primary-05">
                    <tr
                        v-for="(report) in props.digitalFulfillmentReport.notExported"
                        :key="report.orderId"
                    >
                        <td>
                            <Link
                                class="is-clickable is-underlined"
                                :href="route('orders.show', { order: report.orderId })"
                            >
                                {{ report.orderId }}
                            </Link>
                        </td>
                        <td>
                            <span
                                class="tag is-small"
                                :class="{
                                    'is-warning': report.digitalFulfillmentStatus === 'pending',
                                    'is-success': report.digitalFulfillmentStatus === 'fulfilled',
                                }"
                            >
                                {{ report.digitalFulfillmentStatus.toUpperCase() }}
                            </span>
                        </td>
                        <td>{{ report.name }}</td>
                        <td>{{ formatNumber(report.price) }}</td>
                        <td>{{ report.quantity }}</td>
                        <td>{{ dayjs(report.dateCreated).format('YYYY-MM-DD [@] h:mma z') }}</td>
                    </tr>
                </tbody>
            </table>
            <div v-else class="has-text-grey-light">
                There are no orders to export.
            </div>
        </div>
    </div>
    <div v-else class="box">
        <div class="skeleton-block"></div>
    </div>
</template>

<script setup lang="ts">
import dayjs from "dayjs";
import {Link} from "@inertiajs/vue3";
import {ReportInjection, Report} from "@/Types/Report";
import {inject, ref} from "vue";
import Dialog from "@/Components/Dialog.vue";

const props = defineProps<{
    digitalFulfillmentReport: Report['digitalFulfillmentReport'];
}>();

const emit = defineEmits<{
    (e: 'export', id?: number): void;
}>();

const previousAreOpen = ref(false);

const {
    formatNumber,
    isExporting,
} = inject<ReportInjection>('shopReports');
</script>

<style scoped>
.table-container {
    overflow-y: auto !important;
}
</style>

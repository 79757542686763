<template>
    <div>
        <div class="is-flex is-justify-content-flex-end" v-if="cartCount > 0">

            <Link :href="route('cart.show')" class="button is-primary is-hidden-widescreen mobile-cart"
                :class="{ 'is-loading': cart.loading }">
            <span class="icon is-small">
                <Icon :icon="mdiCart" />
            </span>
            <span>
                Go To Cart
            </span>
            <span class="tag is-warning ml-2" :class="{ 'is-invisible': cart.loading }">{{ cartCount
                }}</span>
            </Link>

        </div>

        <div class="page-outer columns is-height-fit mb-0">

            <div class="column py-0 is-full-touch is-full-desktop is-height-fit column-animate" :class="{
                'is-three-quarters-widescreen': cartCount > 0 && !renderCartCollapsed,
                'collapsed-cart': cartCount > 0 && renderCartCollapsed,
            }">
                <transition name="page" mode="out-in" appear>
                    <slot />
                </transition>
            </div>

            <Transition name="cart">
                <template v-if="cartCount > 0">
                    <div
                        class="cart-wrapper is-hidden-touch is-hidden-desktop-only column py-0 is-flex is-flex-direction-column has-gap-small ml-auto">
                        <div class="box" style="max-height: 94dvh; overflow-y: auto;">
                            <div class="cart-animate-mask">
                                <template v-if="!cartCollapsed">

                                    <h1 class="is-flex is-align-items-center block">
                                        <div class="box-title is-flex is-align-items-center has-gap-small pb-1 m-0">
                                            <Icon :icon="mdiCart" /> Cart
                                        </div>
                                        <button @click="cartCollapsed = true"
                                            class="button is-ghost is-small ml-auto pr-0">
                                            <Icon :icon="mdiMenuClose"
                                                style="transform: scale(1.25); transform-origin: center right;" />
                                        </button>
                                    </h1>

                                    <div class="cart-items is-flex is-flex-direction-column has-gap-medium pb-5"
                                        style="overflow: hidden;">
                                        <TransitionGroup name="list" @before-enter="animateBoxOnCartChange">
                                            <template v-for="cartItem in cart.cartItems">
                                                <ShopCartItem v-if="cartItem" :cartItem="cartItem"
                                                    :quantity="cartItem.quantity ?? 1" :minimize="true" />
                                            </template>
                                        </TransitionGroup>
                                    </div>

                                    <div class="cart-total block is-width-100 pt-3 is-size-5 has-text-right">
                                        Subtotal: ${{ cart.getTotal.toFixed(2) }}
                                    </div>

                                    <Link :href="'/cart'" class="button is-primary is-width-100 mb-3">
                                    Edit Cart
                                    </Link>

                                    <Link v-if="!checkoutLoading" :href="route('checkout.show')"
                                        class="button is-warning is-width-100" @click="checkoutLoading = true">
                                    Checkout
                                    </Link>
                                    <div v-else class="button is-warning is-width-100 is-loading">
                                        Checkout
                                    </div>

                                </template>

                                <template v-else>

                                    <button @click="cartCollapsed = false"
                                        class="is-flex is-flex-direction-column is-align-items-center has-gap-small is-width-100">
                                        <Icon :icon="mdiCart" />
                                        <div class="tag is-primary is-medium">{{ cartCount }}</div>
                                    </button>

                                </template>
                            </div>
                        </div>
                    </div>
                </template>
            </Transition>
        </div>
    </div>
</template>

<script setup lang="ts">
import { useShopStore } from '@/Stores/ShopStore';
import { mdiCart, mdiMenuClose } from '@mdi/js';
import ShopCartItem from '@/Components/ShopCartItem.vue';
import { useCartStore } from '@/Stores/CartStore';
import { Link, usePage } from '@inertiajs/vue3';
import { computed, nextTick, onMounted, ref, watch } from 'vue';
import Icon from "@/Components/Icon.vue";
import { Flip } from 'gsap/all';
import gsap from 'gsap';

const page = usePage();
const cart = useCartStore();
const shop = useShopStore();
const cartCollapsed = ref(false);
const renderCartCollapsed = ref(false);
const checkoutLoading = ref(false);

onMounted(async () => {
    const shopId = Number(page.props.shopId);
    await shop.initialize(shopId);
    await cart.initialize();
});

const cartCount = computed(() => cart.cartItems?.length ?? undefined);

watch(cartCount, (value, oldVal) => {
    if ((value === 0 && oldVal !== 0) || oldVal === undefined || window.matchMedia('(prefers-reduced-motion: reduce)').matches) return;

    const columnState = Flip.getState('.column-animate');

    nextTick(() => {
        Flip.from(columnState, {
            duration: .2,
            ease: 'power2.out',
            simple: true,
        });
    });
});

watch(cartCollapsed, (value) => {
    if (window.matchMedia('(prefers-reduced-motion: reduce)').matches) {
        renderCartCollapsed.value = value;
        return;
    }

    const cartState = Flip.getState('.cart-wrapper > .box');
    const columnState = Flip.getState('.column-animate');

    renderCartCollapsed.value = value;

    nextTick(() => {
        Flip.from(cartState, {
            duration: .5,
            ease: 'power3.out',
            simple: true,
            onStart: () => {
                gsap.set('.cart-animate-mask', {
                    opacity: 0,
                })
                gsap.set('.cart-wrapper > .box', {
                    overflowY: 'hidden',
                    overflowX: 'hidden',
                })
            },
            onComplete: () => {
                gsap.to('.cart-animate-mask', {
                    opacity: 1,
                    duration: .15
                })
                gsap.set('.cart-wrapper > .box', {
                    overflowY: 'auto',
                    overflowX: 'hidden',
                })
            }
        });

        Flip.from(columnState, {
            duration: .13,
            ease: 'power2.out',
            simple: true,
        });
    });
});

function animateBoxOnCartChange() {
    const state = Flip.getState('.cart-items');

    if (!window.matchMedia('(prefers-reduced-motion: reduce)').matches) {
        nextTick(() => {
            Flip.from(state, {
                duration: .3,
                ease: 'power2.inOut',
                simple: true,
                onStart: () => {
                    gsap.set('.cart-wrapper > .box', {
                        overflowY: 'hidden',
                        overflowX: 'hidden',
                    })
                },
                onComplete: () => {
                    gsap.set('.cart-wrapper > .box', {
                        overflowY: 'auto',
                        overflowX: 'hidden',
                    })
                }
            });
        });
    }
}
</script>

<style lang="scss" scoped>
@use 'bulma/sass/utilities/mixins';

.cart-total:not(.is-skeleton) {
    border-top: 1px solid #ffffff20;
}

.cart-wrapper {
    width: 20rem;
    max-width: 20rem;
    position: sticky;
    top: 3dvh;
    height: fit-content;
}

.mobile-cart {
    position: fixed;
    bottom: 0;
    right: 0;
    height: 3rem;
    margin: 1rem;
    z-index: 10;
    padding-left: 1.5rem !important;
}

@include mixins.tablet {
    .mobile-cart {
        height: 4rem;
        font-size: 1.2em;
        gap: .25rem;
    }
}

@include mixins.until-widescreen {
    .page-outer {
        flex-direction: column;
    }
}

@include mixins.widescreen {
    .column:first-child {
        padding-left: 0;
    }

    .column:last-child {
        padding-right: 0;
    }

    .column.collapsed-cart {
        flex: none;
        width: 94%;
    }
}

.cart-enter-active,
.cart-leave-active {
    transition: all .3s cubic-bezier(0.215, 0.610, 0.355, 1.000);
    transition-delay: .1s;
}

.cart-enter-from,
.cart-leave-to {
    opacity: 0;
    transform: translateX(8rem);
}

.list-move,
.list-enter-active,
.list-leave-active {
    transition: all 0.3s cubic-bezier(0.215, 0.610, 0.355, 1.000);
    transition-delay: .1s;
}

.list-enter-from,
.list-leave-to {
    opacity: 0;
    transform: translateY(12rem);
}

@media (prefers-reduced-motion) {

    .list-enter-active,
    .list-leave-active,
    .cart-enter-active,
    .cart-leave-active {
        transition: none;
    }
}
</style>

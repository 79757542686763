<template>
    <div class="box" v-if="props.ordersByLocationReport">
        <h1 class="box-title">Orders By Location</h1>

        <VisSingleContainer class="block sub-box" :data="{ areas: USOrdersMapData }" style="min-height: 42vh;">
            <VisTopoJSONMap :topojson="USATopoJSON" :projection="MapProjection.AlbersUsa()" :zoomFactor="1" disable-zoom
                mapFeatureName="states" :pointRadius="0" />
            <div class="map-key">
                <span>Least</span>
                <div class="key"></div>
                <span>Most</span>
            </div>
        </VisSingleContainer>

        <div class="table-container">
            <table class="table is-width-100 block">
                <thead class="has-background-primary-25">
                    <tr>
                        <th>Country</th>
                        <th>State</th>
                        <th>Amount</th>
                    </tr>
                </thead>
                <tbody class="has-background-primary-05 block">
                    <template v-for="(data, country) in ordersByLocationReport" :key="country">
                        <tr v-for="(amount, state) in data" :key="state">
                            <td>{{ country }}</td>
                            <td>{{ state }}</td>
                            <td>{{ amount }}</td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
    </div>
    <div v-else class="box">
        <div class="skeleton-block"></div>
    </div>
</template>

<script setup lang="ts">
import { STATE_TO_ID_MAP } from "@/Helpers/map";
import { USATopoJSON } from "@unovis/ts/maps";
import { MapProjection } from "@unovis/ts";
import { VisSingleContainer, VisTopoJSONMap } from "@unovis/vue";
import { Report } from "@/Types/Report";
import { ref, watch } from "vue";

const props = defineProps<{
    ordersByLocationReport: Report['ordersByLocationReport'];
}>();

watch(() => props.ordersByLocationReport, (newVal) => {
    if (!newVal) {
        return;
    }

    const data = newVal['US'];
    if (!data) {
        return;
    }

    const highest = Math.max(...Object.values(data)) - 1;

    const mapData = [];
    for (const state in data) {
        const lightness = highest > 1 ? 20 + ((data[state] - 1) / highest) * 70 : 90;
        mapData.push({
            id: STATE_TO_ID_MAP[state],
            color: `hsl(var(--bulma-primary-h), 80%, ${lightness}%)`,
        });
    }

    USOrdersMapData.value = mapData;
});

const USOrdersMapData = ref(null);
</script>

<template>
    <div class="is-height-100">
        <template v-if="skeleton">
            <div class="box is-marginless is-flex is-flex-direction-column shop-item-anim">
                <div class="is-skeleton mb-3">
                    <div id="image-wrapper" style="aspect-ratio: 1 / 1;" />
                </div>
                <div class="is-flex is-flex-direction-column has-gap-medium is-height-100">
                    <div class="is-size-3 is-skeleton is-width-100">title</div>

                    <div class="is-flex has-gap-medium is-width-100 is-height-100">

                        <div class="is-flex is-flex-direction-column has-gap-small is-size-6 is-flex-grow-1 mr-6">
                            <div class="is-skeleton">loading</div>
                            <div class="is-skeleton mr-6">loading</div>
                        </div>

                        <PriceTag class="is-skeleton is-width-fit is-size-5 mt-auto">$0.00</PriceTag>
                    </div>
                </div>
            </div>
        </template>

        <Link v-else-if="item" :href="route('shop.items.show', { shop: shop.slug, item: item.slug })"
            class="box is-marginless is-flex is-flex-direction-column is-height-100">

        <div class="mb-3" id="image-wrapper" v-if="item.images?.length ?? 0 > 0">
            <ImgSelector :srcArr="images" :alt="item.title" />
        </div>

        <div class="is-flex is-flex-direction-column has-gap-medium is-height-100">
            <h1 class="is-size-3 has-text-weight-medium">{{ item?.title }}</h1>

            <div class="is-flex has-gap-medium is-width-100 is-height-100">

                <div class="is-flex is-flex-direction-column has-gap-small is-size-6 is-flex-grow-1">

                    <div v-if="item.artistName" class="is-flex is-align-items-center has-gap-small">
                        <Icon :icon="mdiAccountEdit" />
                        <span class="has-text-weight-medium">{{ item.artistName }}</span>
                    </div>

                    <div v-if="notNullColors.length > 0"
                        class="is-flex is-align-items-center has-gap-small is-flex-wrap-wrap">
                        <Icon :icon="mdiPalette" />
                        <div v-for="variant in notNullColors" :key="variant.id" class="color-variant">
                            <div class="color-circle" :title="variant.variantName"
                                :style="{ backgroundColor: variant.color }" />
                        </div>
                    </div>
                </div>

                <PriceTag class="has-text-weight-bold is-width-fit is-size-5 mt-auto">
                    <p>${{ item.price.toFixed(2) }}</p>
                </PriceTag>
            </div>
        </div>
        </Link>
    </div>
</template>

<script setup lang="ts">
import { type PropType, computed } from 'vue';
import type { Item } from '@/Types/Item';
import { Link } from '@inertiajs/vue3';
import { Shop } from "@/Types/Shop";
import ImgSelector from "@/Components/ImgSelector.vue";
import PriceTag from './PriceTag.vue';
import Icon from './Icon.vue';
import { mdiAccountEdit, mdiPalette } from '@mdi/js';

const images = computed(() => {
    return props.item?.images.map((image) => image.url) ?? [];
});

const colorVariants = computed(() => {
    return props.item?.variants?.filter((variant) => variant.type === 'color') ?? [];
});

const notNullColors = computed(() => {
    return colorVariants.value.filter((variant) => variant.color !== null);
});

const props = defineProps({
    shop: {
        type: Object as PropType<Shop>,
        required: false
    },
    item: {
        type: Object as PropType<Item>,
        required: false
    },
    skeleton: {
        type: Boolean as PropType<boolean>,
        default: false
    }
});

</script>

<style scoped lang="scss">
@use "bulma/sass/utilities/mixins";

a {
    transition: box-shadow 0.1s;
}

.box {
    width: 100%;
}

#image-wrapper {
    width: 100%;
    min-width: 20rem;
}

.color-circle {
    width: 1.25em;
    height: 1.25em;
    border-radius: 50%;
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.1);
}

@include mixins.mobile {
    .box {
        max-width: 100%;
        width: 100%;
    }

    #image-wrapper {
        min-width: unset;
    }
}
</style>

<template>
    <div class="box" v-if="props.orderActivityReport">
        <h1 class="box-title">Order Activity</h1>


        <VisXYContainer class="block" :data="orderActivityGraph" style="min-height: 40vh;">
            <VisLine :x="(d) => d.x" :y="(d) => d.y" />
            <VisAxis type="x" :tickValues="orderActivityGraph.map(t => t.x)"
                     :tickFormat="orderActivityTickFormatter" :tickTextAngle="20" tickTextAlign="left" />
            <VisAxis type="y" />
        </VisXYContainer>

        <div class="table-container">
            <table class="table is-width-100">
                <thead class="has-background-primary-25">
                <tr>
                    <th>Date</th>
                    <th>Count</th>
                </tr>
                </thead>
                <tbody class="has-background-primary-05">
                <tr v-for="[key, val] in Object.entries(props.orderActivityReport)" :key="val.timestamp + 'activity'">
                    <td>{{ key }}</td>
                    <td>{{ val.count }}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div v-else class="box">
        <div class="skeleton-block"></div>
    </div>
</template>

<script setup lang="ts">
import {VisAxis, VisLine, VisXYContainer} from "@unovis/vue";
import {Report} from "@/Types/Report";
import {computed} from "vue";

const props = defineProps<{
    orderActivityReport: Report['orderActivityReport'];
}>();

const orderActivityGraph = computed(() => {
    if (!props.orderActivityReport) {
        return;
    }

    const obj = [];
    for (const [key, val] of Object.entries(props.orderActivityReport)) {
        obj.push({
            label: key,
            x: val.timestamp,
            y: val.count,
        });
    }

    return obj;
});

function orderActivityTickFormatter(v: number, i: number, _t: number[]) {
    let graphEntries = orderActivityGraph.value;

    if (!graphEntries) {
        return '';
    }

    let entryIdx = graphEntries.findIndex(entry => {
        return entry.x === v;
    });

    if (entryIdx > -1) {
        return graphEntries[entryIdx].label;
    }
    else {
        return '';
    }
}

</script>

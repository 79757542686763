<template>
    <div class="is-flex is-flex-direction-column is-width-100">
        <PageHead title="Privacy" />

        <div class="box">
            <div name="termly-embed" data-id="6aa0e767-508b-4007-959c-bba919b222d5" data-type="iframe"></div>
        </div>
    </div>
</template>

<script setup lang="ts">
import Base from "@/Layouts/Base.vue";
import Default from "@/Layouts/Default.vue";
import {nextTick, onMounted} from "vue";
import PageHead from "@/Components/PageHead.vue";

defineOptions({
    layout: [Base, Default]
});

onMounted(() => {
    const script = document.createElement("script");
    script.src = "https://app.termly.io/embed-policy.min.js";
    script.async = true;
    document.body.appendChild(script);

    nextTick(() => {
        const iframe = document.querySelector('iframe');
        if (iframe) {

            const styleTag = document.createElement('style');
            styleTag.innerHTML = `
            [data-custom-class='body'], [data-custom-class='body'] * {
                background: transparent !important;
            }
            [data-custom-class='title'], [data-custom-class='title'] * {
                font-family: Arial Black !important;
                font-size: 26px !important;
                color: #ffffff !important;
            }
            [data-custom-class='subtitle'], [data-custom-class='subtitle'] * {
                font-family: Arial Black !important;
                color: #d6e8e7 !important;
                font-size: 14px !important;
            }
            [data-custom-class='heading_1'], [data-custom-class='heading_1'] * {
                font-family: Arial !important;
                font-size: 19px !important;
                color: #ffffff !important;
            }
            [data-custom-class='heading_2'], [data-custom-class='heading_2'] * {
                font-family: Arial !important;
                font-size: 17px !important;
                color: #ffffff !important;
            }
            [data-custom-class='body_text'], [data-custom-class='body_text'] * {
                color: #d6e8e7 !important;
                font-size: 14px !important;
                font-family: Arial !important;
            }
            [data-custom-class='link'], [data-custom-class='link'] * {
                color: #1f918e !important;
                font-size: 14px !important;
                font-family: Arial !important;
                word-break: break-word !important;
            }
            `;

            iframe.contentDocument.head.appendChild(styleTag);
        }
    })
});
</script>

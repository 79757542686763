<template>
    <input v-model="timeVal" type="datetime-local" class="is-primary input is-width-100 has-text-white" />
</template>

<script setup lang="ts">
import dayjs from 'dayjs';
import { onMounted, ref, watch } from 'vue';

const timeVal = ref<string>('');
const model = defineModel<string | undefined>();

onMounted(() => {
    if (model.value) {
        timeVal.value = dayjs(model.value).format('YYYY-MM-DDTHH:mm');
    }
});

watch(timeVal, (value) => {
    try {
        model.value = dayjs(value).toISOString();
    } catch (error) {
        model.value = undefined;
    }
});

watch(model, (value) => {
    timeVal.value = dayjs(value).format('YYYY-MM-DDTHH:mm');
});
</script>
<template>
    <div class="wrapper" v-if="status !== 'cancelled'">

        <div class="status" :class="{
            'has-background-warning has-text-dark active': status === 'pending',
            'has-background-success has-text-dark': orderLevel > 1
        }">
            <Icon :icon="mdiClock"></Icon>
            <div class="label left">Pending</div>
        </div>

        <div class="spacer" :class="{
            'completed': orderLevel > 1,
            'in-progress': status === 'in-production'
        }"></div>

        <div class="status" :class="{
            'has-background-warning has-text-dark active': status === 'in-production',
            'has-background-success has-text-dark': orderLevel > 2
        }">
            <Icon :icon="mdiPackageVariant"></Icon>
            <div class="label center">In Production</div>
        </div>

        <div class="spacer" :class="{
            'completed': orderLevel > 3,
            'in-progress': status === 'shipped'
        }"></div>

        <div class="status" :class="{
            'has-background-warning has-text-dark active': status === 'shipped',
            'has-background-success has-text-dark': orderLevel > 3
        }">
            <Icon :icon="mdiTruckDelivery"></Icon>
            <div class="label right">Shipped</div>
        </div>

        <!-- <div class="spacer" :class="{
            'completed': status === 'delivered',
        }"></div>

        <div class="status" :class="{
            'has-background-success has-text-dark active': status === 'delivered'
        }">
            <Icon :icon="mdiPackageVariantClosedCheck"></Icon>
            <div class="label right">Delivered</div>
        </div> -->
    </div>
    <div class="wrapper" v-else-if="status === 'cancelled'">
        <div class="spacer has-background-danger is-hidden-mobile"></div>
        <div class="status has-background-danger-60 has-text-dark">
            <Icon :icon="mdiPackageVariantClosedRemove"></Icon>
            <div class="label center">Cancelled</div>
        </div>
        <div class="spacer has-background-danger is-hidden-mobile"></div>
    </div>
</template>

<script setup lang="ts">
import { mdiClock, mdiPackageVariant, mdiPackageVariantClosedCheck, mdiPackageVariantClosedRemove, mdiTruckDelivery } from '@mdi/js';
import { onMounted, ref } from 'vue';
import Icon from './Icon.vue';

const orderLevel = ref(0);

const props = defineProps({
    status: {
        type: String,
        required: true
    }
});

onMounted(() => {
    switch (props.status) {
        case 'pending':
            orderLevel.value = 1;
            break;
        case 'in-production':
            orderLevel.value = 2;
            break;
        case 'shipped':
            orderLevel.value = 3;
            break;
        case 'delivered':
            orderLevel.value = 4;
            break;
    }
});
</script>

<style lang="scss" scoped>
@use 'bulma/sass/utilities/mixins';

.wrapper {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 2.45rem;
}

.status {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    svg {
        transform: scale(1.5);
    }

    .label {
        position: absolute;
        width: fit-content;
        white-space: nowrap;
        font-size: 1.2rem;
    }
}

.status.active {

    .label {
        padding: .05rem .5rem;
        background: rgba($color: white, $alpha: .1);
        border-radius: .2rem;
    }
}

.spacer {
    height: .2rem;
    flex-grow: 1;
    background: var(--bulma-link);
    border-radius: .2rem;
}

.spacer.completed {
    background: var(--bulma-success);
}

.spacer.in-progress {
    --direction: right;
    background: linear-gradient(to var(--direction), var(--bulma-success), var(--bulma-warning));
}

@include mixins.tablet-only {
    .wrapper {
        margin-bottom: 2rem;
    }

    .status {
        width: 3rem;
        height: 3rem;

        svg {
            transform: scale(1.25);
        }

        .label {
            font-size: 1rem;
        }
    }
}

@include mixins.tablet {
    .status {
        .label {
            bottom: -2.25em;
        }

        .label.left {
            left: 0;
        }

        .label.right {
            right: 0;
            text-align: right;
        }

        .label.center {
            left: 50%;
            transform: translateX(-50%);
            text-align: center;
        }
    }
}

@include mixins.mobile {
    .wrapper {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: .75rem;
        margin-bottom: 0;
    }

    .status {
        width: 2.5rem;
        height: 2.5rem;

        svg {
            transform: scale(1);
        }

        .label {
            left: 3.25rem;
        }
    }

    .spacer {
        height: 1.5rem;
        width: .2rem;
        margin-left: calc(2.5rem / 2 - .1rem);
    }

    .spacer.in-progress {
        --direction: bottom;
    }
}
</style>
<template>
    <div v-if="!visible" ref="loadingElement"
        class="is-width-100 is-height-100 is-flex is-align-items-center is-justify-content-center"
        style="background-color: var(--bulma-skeleton-background)">
        <Icon v-if="!error && src && src.length > 0" class="spin" :icon="mdiLoading" style="transform: scale(1.5);" />
        <Icon v-else-if="error && src && src.length > 0" :icon="mdiImageRemove" />
    </div>
    <img v-else :src="props.src" :alt="props.alt" :loading="props.loading" class="loading-img" />
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, PropType, ref, watch } from 'vue';
import Icon from './Icon.vue';
import { mdiImageRemove, mdiLoading } from '@mdi/js';

const props = defineProps({
    src: {
        type: String
    },
    alt: {
        type: String
    },
    loading: {
        type: String as PropType<'lazy' | 'eager'>,
        default: 'lazy'
    }
})

const observer = new IntersectionObserver(observe, {
    root: null,
    threshold: 0
});

const loadingElement = ref<HTMLElement | null>(null);
const visible = ref(false);
const error = ref(false);
let retries = 0;

onMounted(() => {
    if (props.loading === 'eager') {
        handleSrcChange();
        return;
    }

    setTimeout(() => {
        if (loadingElement.value) {
            observer.observe(loadingElement.value);        
        }
    }, 50);
});

onUnmounted(() => {
    if (loadingElement.value) {
        observer.unobserve(loadingElement.value);
    }
});

watch(() => props.src, handleSrcChange);

function observe(entries: IntersectionObserverEntry[]) {
    if (entries[0].isIntersecting) {
        handleSrcChange();
    }
}

function handleSrcChange() {
    visible.value = false;
    error.value = false;

    const img = new Image();
    img.src = props.src;

    img.onload = () => {
        img.decode().then(() => {
            visible.value = true;
            img.removeEventListener('load', img.onload);
            img.removeEventListener('error', img.onerror);
        }).catch(() => {
            error.value = true;
        });
    }
    img.onerror = () => {
        if (retries < 1) {
            handleSrcChange();
            retries++;
        } else {
            error.value = true;
            img.removeEventListener('load', img.onload);
            img.removeEventListener('error', img.onerror);
        }
    }
}
</script>

<style lang="scss" scoped>
.spin {
    animation: spin .4s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>
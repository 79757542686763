<template>
    <div
        class="is-width-100 is-height-100 is-flex is-flex-direction-column is-align-items-center is-justify-content-center has-text-white">

        <PageHead :title="title" :description="message" />

        <div class="is-flex is-flex-direction-column has-gap-medium">
            <p class="is-size-5 tag is-warning is-width-fit">Error {{ props.status }}</p>
            <h1 class="is-size-2">{{ title }}</h1>
            <p class="is-size-4">{{ message }}</p>

            <Link href="/" class="button is-primary is-width-fit is-large mt-6">
                Return home
            </Link>
        </div>
    </div>

</template>

<script setup lang="ts">
import { computed, defineOptions } from "vue";
import Base from "@/Layouts/Base.vue";
import Default from "@/Layouts/Default.vue";
import PageHead from "@/Components/PageHead.vue";
import { Link } from "@inertiajs/vue3";

defineOptions({
    layout: [Base, Default],
});

const props = defineProps<{
    status: number;
}>();

const title = computed(() => {
    const MAP = {
        503: 'Service Unavailable',
        500: 'Server Error',
        404: 'Page Not Found',
        403: 'Forbidden',
    };

    return MAP[props.status] || 'Error';
});

const message = computed(() => {
    const MAP = {
        503: 'Sorry, we are doing some maintenance. Please check back soon.',
        500: 'Whoops, something went wrong on our end. Sorry about that.',
        404: 'Sorry, we couldn\'t find what you were looking for.',
        403: 'Forbidden. You do not have the correct permissions to access this resource.',
    };

    return MAP[props.status] || 'Whoops, something went wrong.';
});

</script>

<template>
    <label class="label" v-if="props.label">{{ props.label }}</label>
    <div class="file is-primary is-fullwidth has-name mb-0" :class="{'is-danger has-background-danger-10': error}">
        <label class="file-label">
            <input class="file-input" type="file" v-bind="$attrs" @change="handleImageChange"/>
            <span class="file-cta">
                <span class="icon mr-2">
                    <Icon :icon="mdiUpload" />
                </span>
                    <span class="file-label"> Choose a file… </span>
                </span>
            <span class="file-name">{{ fileName }}</span>
        </label>
    </div>
    <div class="mb-1">
        <p class="help is-danger" v-if="props.error">{{ props.error }}</p>
        <p class="help" v-else-if="props.helperText">{{ props.helperText }}</p>
    </div>
</template>


<script setup lang="ts">
import Icon from "@/Components/Icon.vue";
import {mdiUpload} from "@mdi/js";
import {ref} from "vue";

const props = defineProps<{
    label?: string,
    helperText?: string,
    error?: string,
}>();

const emit = defineEmits<{
    (e: 'change', event: Event): void;
}>();

const fileName = ref<string>('');
function handleImageChange(e: Event) {
    emit('change', e);
    fileName.value = (e.target as any)?.files[0]?.name;
}

</script>

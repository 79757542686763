<template>
    <Dialog
        :title="isNew ? 'Create Item Variant' : 'Edit Item Variant'"
        :open="open"
        @close="() => emit('close')"
    >
        <ItemVariantForm
            v-model="model"
            @save="() => emit('save', model)"
        />
    </Dialog>
</template>

<script setup lang="ts">
import ItemVariantForm from '@/Components/Item/ItemVariantForm.vue';
import type { ItemVariant } from '@/Types/ItemVariant';
import { ref, computed } from 'vue';
import Dialog from '../Dialog.vue';


const defaultVariant: ItemVariant = {
    id: 0,
    type: "other",
    variantName: '',
    images: [],
};

const model = ref<ItemVariant>({...defaultVariant});

const props = defineProps<{
    modelValue: ItemVariant;
    open: boolean;
}>();

const emit = defineEmits<{
    (e: 'update:modelValue', value: ItemVariant): void;
    (e: 'save', value: ItemVariant): void;
    (e: 'close'): void;
}>();

const isNew = computed(() => {
    return (props.modelValue?.id ?? 0) === 0;
});

</script>

<template>
    <div class="box" v-if="props.averagesReport">
        <h1 class="box-title">Averages</h1>

        <div class="table-container">
            <table class="table is-width-100">
                <thead class="has-background-primary-25">
                <tr>
                    <th>Report</th>
                    <th>Amount</th>
                </tr>
                </thead>
                <tbody class="has-background-primary-05">
                <tr v-for="(amount, report) in props.averagesReport" :key="report">
                    <td>{{ getPrettyFieldName(report) }}</td>
                    <td v-if="report === 'daysToShip'">{{ amount }} days</td>
                    <td v-else>{{ formatNumber(amount) }}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div v-else class="box">
        <div class="skeleton-block"></div>
    </div>
</template>

<script setup lang="ts">
import {ReportInjection} from "@/Types/Report";
import {inject} from "vue";

const props = defineProps<{
    averagesReport: Record<string, number> | null;
}>();

const {
    getPrettyFieldName,
    formatNumber
} = inject<ReportInjection>('shopReports');
</script>

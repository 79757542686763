<template>
    <div class="is-flex is-flex-direction-column is-width-100">
        <PageHead title="Cookie Policy" />

        <div class="box">
            <div name="termly-embed" data-id="d190c50c-49e5-4533-a05c-57654880f2d6" data-type="iframe"></div>
        </div>
    </div>
</template>

<script setup lang="ts">
import Base from "@/Layouts/Base.vue";
import Default from "@/Layouts/Default.vue";
import {onMounted} from "vue";
import PageHead from "@/Components/PageHead.vue";

defineOptions({
    layout: [Base, Default]
});

onMounted(() => {
    const script = document.createElement("script");
    script.src = "https://app.termly.io/embed-policy.min.js";
    script.async = true;
    document.body.appendChild(script);
});
</script>

<template>
    <div class="box is-height-fit">
        <PageHead title="Reset Password" />

        <div class="box-title">
            Reset Password
        </div>

        <div>
            <form @submit.prevent="handleSubmit" novalidate>
                <Field placeholder="Password" type="password" v-model="form.password" :disabled="isLoading" :error="form.errors.password">
                    <template #iconLeft>
                        <Icon :icon="mdiLock" />
                    </template>
                </Field>
                <Field
                    placeholder="Confirm Password"
                    type="password"
                    v-model="form.password_confirmation"
                    :disabled="isLoading"
                    :error="form.errors.password_confirmation ?? ((form.errors as any).token ?? (form.errors as any).email)"
                >
                    <template #iconLeft>
                        <Icon :icon="mdiLockCheck" />
                    </template>
                </Field>

                <div class="is-flex is-align-items-center is-justify-content-flex-end">
                    <div>
                        <button type="submit" class="button is-primary" :class="{'is-loading': isLoading}">
                            <span class="icon is-small">
                                <Icon :icon="mdiLockReset" />
                            </span>
                            <span>
                                Reset Password
                            </span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import Field from '@/Components/common/Field.vue';
import {mdiLock, mdiLockCheck, mdiLockReset} from '@mdi/js'
import {useForm} from '@inertiajs/vue3';
import Icon from '@/Components/Icon.vue';
import Base from '@/Layouts/Base.vue';
import Default from '@/Layouts/Default.vue';
import PageHead from '@/Components/PageHead.vue';

defineOptions({
    layout: [Base, Default],
})

const props = defineProps<{
    token: string;
    email: string;
}>();

const isLoading = ref(false);

const form = useForm({
    password: '',
    password_confirmation: '',
});

async function handleSubmit() {
    form.transform((data) => ({
        ...data,
        token: props.token,
        email: props.email,

    })).post(route('password.update'), {
        onStart: () => {
            isLoading.value = true;
        },

        onFinish: () => {
            isLoading.value = false;
        },
    });
}
</script>

<style lang="scss" scoped>
@use 'bulma/sass/utilities/mixins';

.box {
    max-width: 25rem;
    width: 100%;
}

@include mixins.mobile {
    .box {
        min-width: 100%;
        width: 100%;
    }
}
</style>

<template>
    <div class="box block is-width-100 is-height-fit cart-box">

        <PageHead title="Cart" />

        <div class="box-title is-flex is-align-items-center has-gap-small pb-1 mb-5">
            <Icon :icon="mdiCart" /> Cart
        </div>
        <template v-if="cart.getNumItems === 0">
            <div class="block is-size-5">
                Your cart is empty
            </div>
        </template>

        <template v-else>
            <div class="block" style="overflow-x: hidden; overflow-y: visible; height: 100%; position: relative;">
                <TransitionGroup name="list" @before-leave="animateBox">
                    <ShopCartItem class="block" v-for="(cartItem, itemIndex) in cart.cartItems"
                        :key="getUniqueItemKey(cartItem)" :cart-item="cartItem" :quantity="cartItem.quantity ?? 1"
                        :minimize="false" :index="itemIndex" />

                    <div key="misc"
                        class="block is-flex is-flex-direction-column is-align-items-flex-end has-gap-large pt-5" style="border-top: .15rem solid var(--bulma-primary-35);">
                        <div class="is-size-4 is-flex is-flex-direction-column is-align-items-flex-end has-gap-small">
                            <div class="is-flex is-align-items-center has-gap-small">
                                <div>Subtotal: <strong>${{ cart.getTotal.toFixed(2) }}</strong></div>
                            </div>
                            <div class="is-size-6 has-text-link-50 is-flex is-flex-wrap-wrap is-align-items-center is-justify-content-flex-end has-text-right has-gap-small">
                                <Icon :icon="mdiInformationOutline" /> Tax and other potential fees are calculated at checkout
                            </div>
                        </div>

                        <Link v-if="!checkoutLoading" :href="route('checkout.show')" class="button is-warning is-large"
                            @click="checkoutLoading = true">
                        <Icon :icon="mdiCashRegister" class="mr-2" />
                        <span>Checkout</span>
                        </Link>

                        <div v-else class="button is-warning is-large is-loading">
                            <Icon :icon="mdiCashRegister" class="mr-2" />
                            <span>Checkout</span>
                        </div>

                    </div>
                </TransitionGroup>
            </div>
        </template>
    </div>
</template>

<script setup lang="ts">
import { useCartStore } from "@/Stores/CartStore";
import { nextTick, onMounted, ref } from "vue";
import { Link } from "@inertiajs/vue3";
import ShopCartItem from "@/Components/ShopCartItem.vue";
import Default from "@/Layouts/Default.vue";
import Base from "@/Layouts/Base.vue";
import { CartItem } from "@/Types/CartItem";
import Icon from "@/Components/Icon.vue";
import { mdiCart, mdiCashRegister, mdiInformationOutline } from "@mdi/js";
import gsap from "gsap";
import { Flip } from "gsap/all";
import PageHead from "@/Components/PageHead.vue";

gsap.registerPlugin(Flip);
const cart = useCartStore();

const checkoutLoading = ref(false);

defineOptions({
    layout: [Base, Default],
})

onMounted(async () => {
    await cart.initialize();
});

function getUniqueItemKey(cartItem: CartItem) {
    return cartItem.item.id + '-' + cartItem.variants.map(v => v.id).join('-');
}

function animateBox() {
    const state = Flip.getState('.cart-box');
    nextTick(() => {
        if (!window.matchMedia('(prefers-reduced-motion: reduce)').matches) {
            Flip.from(state, {
                duration: .25,
                ease: 'power2.out',
                simple: true
            });
        }
    });
}

</script>

<style lang="scss" scoped>
@media (prefers-reduced-motion) {

    .list-enter-active,
    .list-leave-active {
        transition: none;
    }
}

.list-move,
.list-enter-active,
.list-leave-active {
    transition: all .25s ease;
}

.list-enter-from,
.list-leave-to {
    opacity: 0;
    transform: translateX(-25%);
}

.list-leave-active {
    position: absolute;
    width: 100%;
}
</style>

<template>
    <p class="control">
        <button
            class="button"
            :class="{ 'is-outlined': !isActive }"
            v-bind="$attrs"
            @click="handleClick"
        >
            <slot />
        </button>
    </p>
</template>

<script setup lang="ts">
import {computed, inject} from "vue";
import {ButtonGroupProvide} from "@/Components/common/ButtonGroup/index";

const props = defineProps<{
    thisValue: string | boolean;
}>();

const {activeValue, updateActiveValue} = inject<ButtonGroupProvide>('buttonGroup');

const emit = defineEmits<{
    (e: 'click'): void;
}>();

const isActive = computed<boolean>(() => {
    return props.thisValue === activeValue.value;
});

function handleClick() {
    emit('click');
    updateActiveValue(props.thisValue);
}
</script>

<template>

    <div v-if="form.item.images.length > 0" class="block is-flex is-flex-direction-column has-gap-medium">

        <div class="is-flex is-align-items-center has-gap-medium has-text-white has-background-primary-10 p-3"
            v-for="image in form.item.images" style="border-radius: .5rem;">

            <button class="button is-ghost is-small has-text-danger px-0"
                @click="deleteImage(image.id)">
                <Icon :icon="mdiClose" />
            </button>

            <LoadingImg :src="image.url" style="width: 7rem; height: 7rem; border-radius: .5rem;" />

            <div class="is-width-100 ml-3">

                <div class="mb-1 is-size-6">Link to variant:</div>

                <div class="is-primary is-width-100 select">
                    <select class="is-width-100" v-model="image.variantId" @change="linkImage(image.id, image.variantId)">
                        <option :value="0">None</option>
                        <option v-for="variant in item.variants" :key="variant.id" :value="variant.id">
                            {{ variant.variantName }}
                        </option>
                    </select>
                </div>

            </div>
        </div>
    </div>

    <div class="block is-width-100 is-size-5 ">
        <FileInput label="Upload New Image" accept="image/*" :error="form.errors.item"
            helper-text="Recommended size: 500x500 or 1:1 aspect ratio" @change="newImage($event)" />
    </div>

</template>

<script setup lang="ts">
import { Item } from '@/Types/Item';
import {useForm, usePage} from '@inertiajs/vue3';
import { mdiClose } from '@mdi/js';
import {onMounted, PropType, ref} from 'vue';
import LoadingImg from '../LoadingImg.vue';
import Icon from '../Icon.vue';
import FileInput from '../common/FileInput.vue';
import { useShopStore } from '@/Stores/ShopStore';

const props = defineProps({
    shopId: {
        type: Number,
        required: true
    },
    item: {
        type: Object as PropType<Item>,
        required: true
    }
});

const shop = useShopStore();
const page = usePage();

onMounted(() => {
    shop.initialize(page?.props.shopId);
});

const form = useForm({
    item: {
        id: props.item.id,
        images: props.item.images
    }
});

async function newImage(event: Event) {
    const thisRoute = route('admin.shops.items.images.add', { shop: page.props.shopSlug, item: props.item.slug });
    const formPayload = new FormData();
    formPayload.append('image', (event.target as HTMLInputElement).files?.[0] as Blob);

    const response = await fetch(thisRoute, {
        method: 'POST',
        body: formPayload,
        headers: {
            'X-CSRF-TOKEN': page.props.csrf
        }
    });

    const {status, payload} = await response.json();
    if (status !== 'OK') {
        throw new Error(payload?.toString());
    }

    form.item.images.push(payload);
}

async function linkImage(imageId: number, variantId?: number|null) {
    const thisRoute = route('admin.shops.items.images.link', { shop: page.props.shopSlug, item: props.item.slug, image: imageId });
    const formPayload = new FormData();
    formPayload.append('variantId', variantId?.toString() as string);

    const response = await fetch(thisRoute, {
        method: 'POST',
        body: formPayload,
        headers: {
            'X-CSRF-TOKEN': page.props.csrf
        }
    });

    const {status, payload} = await response.json();
    if (status !== 'OK') {
        throw new Error(payload?.toString());
    }

    const itemIndex = form.item.images.findIndex((image) => image.id === imageId);
    form.item.images[itemIndex] = payload;
}

async function deleteImage(imageId: number) {
    const thisRoute = route('admin.shops.items.images.delete', { shop: page.props.shopSlug, item: props.item.slug, image: imageId });

    const response = await fetch(thisRoute, {
        method: 'DELETE',
        headers: {
            'X-CSRF-TOKEN': page.props.csrf
        }
    });

    const {status, payload} = await response.json();
    if (status !== 'OK') {
        throw new Error(payload?.toString());
    }

    form.item.images.splice(form.item.images.findIndex((image) => image.id === imageId), 1);
}

</script>

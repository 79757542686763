<template>
    <div class="nav-wrapper">
        <div class="nav-background"></div>
        <nav class="is-flex is-align-items-center has-gap-large is-width-100">

            <Link href="/">
                <div class="is-flex is-align-items-center" style="gap: 0.75rem;">
                    <span>
                        <img class="is-hidden-mobile" src="/public/imgs/level1.png" alt="Level1 Logo">
                        <img class="is-hidden-tablet" src="/public/imgs/level1-bowtie.png" alt="Level1 Logo">
                    </span>
                    <span class="has-text-link has-text-weight-bold is-size-4" style="margin-top: .7rem">
                        Shop
                    </span>
                </div>
            </Link>

            <div class="divider is-hidden-mobile" v-if="otherNav"></div>

            <div class="breadcrumb mb-0 is-size-5 has-text-link has-text-weight-semibold is-hidden-mobile"
                style="margin-top: .83rem">
                <ul>
                    <li v-if="itemNav" class="is-hidden-touch">
                        <Link :href="`/${shop.id}/${shop.slug}/${itemNav?.id}/${itemNav.slug}`">
                        {{ itemNav?.title }}
                        </Link>
                    </li>
                    <li v-else-if="otherNav" class="is-hidden-touch">
                        <Link :href="`${otherNav.path}`">
                        {{ otherNav.title }}
                        </Link>
                    </li>
                </ul>
            </div>

            <div v-if="!page?.props?.auth?.user" class="ml-auto is-flex is-uppercase has-text-weight-semibold"
                style="gap: 1rem;">
                <span>
                    <Link href="/register">
                    Register
                    </Link>
                </span>
                <span>
                    <Link href="/login">
                    Login
                    </Link>
                </span>
            </div>
            <div v-else class="ml-auto is-flex is-align-items-center">
                <div class="dropdown is-right">
                    <button class="dropdown-trigger" @click="dropdownVisible = !dropdownVisible" ref="dropdownTrigger">
                        <p class="icon-text has-text-link">
                            <span class="icon">
                                <Icon :icon="mdiAccountCircle" />
                            </span>
                            <span class="is-hidden-desktop">
                                {{ page.props.auth.user?.firstName }}
                            </span>
                            <span class="is-hidden-touch">
                                {{ page.props.auth.user?.firstName }} {{ page.props.auth.user?.lastName }}
                            </span>
                            <span v-if="page.props.auth.can?.viewAdminDashboard">
                                <span class="tag is-uppercase is-warning ml-2">
                                    Admin
                                </span>
                            </span>
                        </p>
                    </button>
                    <Transition name="dropdown">
                        <div v-if="dropdownVisible" class="dropdown-menu is-block" ref="dropdownMenu"
                            style="min-width: 18em;">
                            <div class="dropdown-content py-4 px-1 mt-3">
                                <div class="dropdown-item">
                                    <Link :href="route('profile.show')" class="button is-primary has-gap-small is-fullwidth">
                                    <Icon :icon="mdiAccountCircle" />
                                    Account
                                    </Link>
                                </div>
                                <div class="dropdown-item">
                                    <Link :href="route('profile.orders')" class="button is-primary has-gap-small is-fullwidth">
                                    <Icon :icon="mdiPackageVariant" />
                                    Your Orders
                                    </Link>
                                </div>
                                <div class="dropdown-item" v-if="page.props.auth.can?.viewAdminDashboard">
                                    <Link class="button is-primary has-gap-small is-fullwidth" href="/admin">
                                    <Icon :icon="mdiStoreCog" />
                                    <span>Shops Config Panel</span>
                                    <span class="tag is-uppercase is-warning">Superadmin</span>
                                    </Link>
                                </div>
                                <div class="dropdown-item">
                                    <button @click="handleSignOut" class="button is-fullwidth has-gap-small is-warning">
                                        <Icon :icon="mdiLogout" />
                                        Sign Out
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Transition>
                </div>
            </div>
        </nav>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue';
import { type Item } from '@/Types/Item';
import Icon from './Icon.vue';
import { mdiAccountCircle, mdiLogout, mdiPackageVariant, mdiStoreCog } from '@mdi/js';
import { Link } from '@inertiajs/vue3';
import { useShopStore } from '@/Stores/ShopStore';
import { usePage } from '@inertiajs/vue3';
import { router } from '@inertiajs/vue3';

const page = usePage();

const shop = useShopStore();
const itemNav = ref<Item | null>(null);
const otherNav = ref<{ title: string, path: string } | null>(null);
const dropdownMenu = ref<HTMLElement | null>(null);
const dropdownTrigger = ref<HTMLElement | null>(null);
const dropdownVisible = ref(false);

onMounted(() => {
    document.addEventListener('click', (e: Event) => {
        if (!dropdownMenu.value || !dropdownTrigger.value) {
            return;
        }
        if (dropdownVisible.value && !e.composedPath().includes(dropdownTrigger.value)) {
            dropdownVisible.value = false;
        }
    });
});

onUnmounted(() => {
    document.removeEventListener('click', () => { });
});

async function handleSignOut() {
    router.post(route('logout'));
}
</script>

<style lang="scss" scoped>
@use 'sass:color';
@use 'bulma/sass/utilities/mixins';

.nav-wrapper {
    width: 100%;
    height: 7rem;
    padding-top: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    position: relative;
    z-index: 20;
}

.nav-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% + 2rem);
    background-color: hsla(var(--bulma-primary-h), var(--bulma-primary-s), 5%, .25);
    backdrop-filter: blur(2rem);
    mask: linear-gradient(to bottom, black calc(100% - 2rem), transparent 100%);
    z-index: -1;

}

nav {
    z-index: 100;
    margin: 0 auto;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    max-width: calc(1600px - 2rem);
    border-bottom: 1px solid rgba(221, 221, 221, 0.1);
}

img {
    height: 2.9em;
    margin-top: .2rem;
}

@include mixins.mobile {
    .nav-wrapper {
        padding: 0 1rem;
    }

    nav {
        padding: 1rem 0;
    }

    img {
        height: 2.3em;
    }

    .nav-background {
        height: calc(100% + 1rem);
    }
}

.divider {
    width: 1px;
    height: 100%;
    background: rgba(221, 221, 221, 0.1);
}

.breadcrumb {
    --bulma-breadcrumb-item-separator-color: rgba(221, 221, 221, 0.1);
}

.dropdown-enter-active,
.dropdown-leave-active {
    transition: all 0.1s ease;
}

.dropdown-enter-from,
.dropdown-leave-to {
    transform: translateY(-1rem);
    opacity: 0;
}
</style>

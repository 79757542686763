<template>
    <div class="is-flex is-flex-direction-column is-justify-content-center is-clickable" style="gap: .75rem;"
        @click="expanded = !expanded">
        <div class="is-size-4 is-flex is-align-items-center has-gap-medium">
            <div class="is-size-6 has-background-primary-15 has-text-white is-uppercase has-text-weight-semibold px-2"
                style="border-radius: .5rem;">Goal</div>
            <div>{{ name }}</div>
            <div v-if="!expanded" class="is-primary ml-auto tag" style="font-size: .85rem;">
                Click for details
            </div>
        </div>
        <div class="is-flex is-flex-direction-column has-gap-small">
            <div id="goal-progress">
                <div class="progress-bar" ref="progressBar"></div>
            </div>
            <div class="is-size-6 is-size-5-desktop has-text-weight-semibold is-flex has-gap-small is-width-100">
                <div class="is-flex-grow-1">${{ animatedProgress.toFixed(2) }} / ${{ target.toFixed(2) }}</div>
                <div v-if="goalOpen && !expanded && !isReached" class="tag is-warning is-size-6">Active</div>
                <div v-else-if="!goalOpen && !expanded && !isReached" class="has-text-white">Ended</div>
                <div v-else-if="isReached" class="has-text-success">Goal reached!</div>
            </div>
        </div>

        <Transition @enter="expandedEnter" @leave="expandedLeave">
            <div ref="expandedElem" v-if="expanded" class="is-flex is-flex-direction-column has-gap-medium" style="overflow: hidden;">

                <div class="is-size-6">
                    {{ description }}
                </div>

                <div v-if="goalOpen" class="is-size-6 tag is-warning is-width-fit is-height-fit py-1" style="text-wrap: wrap;">
                    Goal ends {{ new Date(dateClose).toLocaleString([], {
                        year: 'numeric', month: 'long', day: '2-digit', hour: '2-digit', minute: '2-digit'
                    }) }}
                </div>
                <div v-else class="is-size-6 tag has-text-white has-background-warning-15 is-width-fit is-height-fit py-1" style="text-wrap: wrap;">
                    Goal ended on {{ new Date(dateClose).toLocaleString([], {
                        year: 'numeric', month: 'long', day: '2-digit'
                    }) }}
                </div>
            </div>
        </Transition>
    </div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs';
import gsap from 'gsap';
import { computed, onMounted, ref } from 'vue';

const animatedProgress = ref(0);
const isReached = ref(false);
const progressBar = ref<HTMLElement | null>(null);
const expanded = ref(false);
const expandedElem = ref<HTMLElement | null>(null);

const goalOpen = computed(() => {
    return dayjs().isBefore(props.dateClose);
});

const props = defineProps({
    name: {
        type: String,
        required: true
    },
    current: {
        type: Number,
        required: true
    },
    target: {
        type: Number,
        required: true
    },
    description: {
        type: String,
        required: false,
        default: ''
    },
    dateClose: {
        type: String,
        required: true
    }
})

onMounted(() => {
    const animationParams = {
        duration: (Math.min(props.current, props.target) / props.target) * 2.2,
        delay: .4,
        ease: props.current >= props.target ? "power1.out" : "power3.out"
    }

    gsap.to(animatedProgress, {
        value: Math.min(props.current, props.target),
        ...animationParams,
        onComplete: function () {
            isReached.value = animatedProgress.value >= props.target;
        }
    });

    gsap.to(progressBar.value, {
        width: `${(Math.min(props.current, props.target) / props.target) * 100}%`,
        ...animationParams,
        onComplete: () => {
            if (props.current >= props.target) {

                gsap.fromTo(progressBar.value, {
                    background: "#d6e8e7"
                }, {
                    background: "#48c78e",
                    duration: .5,
                    ease: "power2.out"
                });

            } else {

                if (!goalOpen.value) {
                    return;
                }


                gsap.fromTo(progressBar.value, {
                    '--bg-offset': 'calc(0% - 40rem)',
                }, {
                    '--bg-offset': 'calc(100% - 0rem)',
                    repeat: -1,
                    duration: 1.5,
                    ease: "power1.in"
                });

            }
        }
    });
});

function expandedEnter(el, done) {
    gsap.fromTo(el, {
        height: 0,
    }, {
        height: "auto",
        duration: .15,
        ease: "power2.inOut",
        onComplete: done
    });
}

function expandedLeave(el, done) {
    gsap.to(el, {
        height: 0,
        duration: .15,
        ease: "power2.inOut",
        onComplete: done
    });
}

</script>

<style lang="scss" scoped>
@use 'sass:color';
@use 'bulma/sass/utilities/mixins';

#goal-progress {
    width: 100%;
    height: 2rem;
    border-radius: var(--bulma-radius);
    background-color: var(--bulma-primary-10);
    overflow: hidden;

    .progress-bar {
        width: 0;
        height: 100%;
        border-radius: var(--bulma-radius);
        --bg-offset: calc(0% - 40rem);
        background: linear-gradient(to right,
                var(--bulma-link-text) var(--bg-offset),
                var(--bulma-primary-80) calc(var(--bg-offset) + 38rem),
                var(--bulma-link-text) calc(var(--bg-offset) + 40rem));
    }
}

@include mixins.tablet-only {
    #goal-progress {
        height: 1.25rem;
    }
}

@include mixins.mobile {
    #goal-progress {
        height: .75rem;
    }
}
</style>
import dayjs from "dayjs";

export function shopIsOpen(open?: string, close?: string): boolean {
    if (!open || !close) {
        return false;
    }

    const timeStart = new Date(open);
    const timeEnd = new Date(close);
    const now = new Date();

    return timeStart.getTime() <= now.getTime() && timeEnd.getTime() >= now.getTime();
}

export function shopState(open?: string, close?: string): 'open' | 'opening-soon' | 'closed' {
    if (!open || !close) {
        return 'closed';
    }

    const timeStart = dayjs(open);
    const timeEnd = dayjs(close);
    const now = dayjs();

    if (timeStart.isAfter(now)) {
        return 'opening-soon';
    }

    if (timeEnd.isAfter(now) && timeEnd.isAfter(now)) {
        return 'open';
    }

    return 'closed';
}

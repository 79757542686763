<template>
    <div class="is-flex" :class="{
        'is-flex-direction-row is-align-items-baseline has-gap-medium is-flex-wrap-wrap is-justify-content-flex-start': compact,
        'is-flex-direction-column': !compact
    }" :style="{
        'row-gap': compact ? '0' : ''
    }">
        <div class="is-size-5" :class="{
            'tag is-danger is-width-fit': status == 'closed',
        }">
            {{ statusText }}
        </div>
        <div v-if="status != 'closed'"
            class="is-size-2 is-size-3-mobile has-text-weight-bold is-flex is-align-items-baseline has-gap-medium"
            style="font-variant-numeric: tabular-nums;">
            <span>
                {{ days.toString().padStart(2, '0') }}<span class="is-size-5 has-text-weight-light ml-1">d</span>
            </span>
            <span>
                {{ hours.toString().padStart(2, '0') }}<span class="is-size-5 has-text-weight-light ml-1">h</span>
            </span>
            <span>
                {{ minutes.toString().padStart(2, '0') }}<span class="is-size-5 has-text-weight-light ml-1">m</span>
            </span>
            <span>
                {{ seconds.toString().padStart(2, '0') }}<span class="is-size-5 has-text-weight-light ml-1">s</span>
            </span>
        </div>
        <div v-if="timeOfInterest.length > 0" class="is-size-6 mt-2">
            {{ timeOfInterest }}
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from 'vue';

const status = ref<'awaiting-open' | 'open' | 'closed'>('awaiting-open');
const timeOfInterest = ref("");
const days = ref(0);
const hours = ref(0);
const minutes = ref(0);
const seconds = ref(0);
const openEpoch = ref(0);
const closeEpoch = ref(0);
let interval: any;

const props = defineProps({
    openTimestamp: {
        type: String,
        required: true
    },
    closeTimestamp: {
        type: String,
        required: true
    },
    compact: {
        type: Boolean,
        default: false
    }
});

const statusText = computed(() => {
    switch (status.value) {
        case 'awaiting-open': return 'Shop Opening In';
        case 'open': return 'Shop Closes In';
        case 'closed':
            if (props.closeTimestamp) {
                return `Shop Closed On ${new Date(props.closeTimestamp).toLocaleString([], {
                    year: 'numeric', month: 'long', day: '2-digit'
                })}`;
            } else {
                return 'Shop Closed';
            }
    }
});

onMounted(() => {
    openEpoch.value = new Date(props.openTimestamp).getTime() / 1000;
    closeEpoch.value = new Date(props.closeTimestamp).getTime() / 1000;
    interval = setInterval(updateTime, 1000);
    updateTime();
});

onUnmounted(() => {
    if (interval) clearInterval(interval);
});

function updateTime() {
    const now = Math.floor(Date.now() / 1000);
    const open = openEpoch.value;
    const close = closeEpoch.value;

    status.value = now < open ? 'awaiting-open' : now < close ? 'open' : 'closed';
    let targetTime: number;

    switch (status.value) {
        case 'awaiting-open':
            targetTime = open - now;
            timeOfInterest.value = new Date(open * 1000).toLocaleString([], {
                year: 'numeric', month: 'long', day: '2-digit', hour: '2-digit', minute: '2-digit'
            });
            break;
        case 'open':
            targetTime = close - now;
            timeOfInterest.value = new Date(close * 1000).toLocaleString([], {
                year: 'numeric', month: 'long', day: '2-digit', hour: '2-digit', minute: '2-digit'
            });
            break;
        case 'closed':
            if (interval) clearInterval(interval);
            timeOfInterest.value = '';
            return;
    }

    days.value = Math.floor(targetTime / 86400);
    hours.value = Math.floor((targetTime % 86400) / 3600);
    minutes.value = Math.floor((targetTime % 3600) / 60);
    seconds.value = Math.floor(targetTime % 60);
}

</script>

<template>
    <div class="columns is-multiline">
        <div class="column is-12">
            <Field
                is="select"
                label="Type"
                v-model="model.type"
                placeholder="Select Type"
            >
                <option value="size">Size</option>
                <option value="color">Color</option>
                <option value="other">Other</option>
            </Field>
        </div>

        <div v-if="model.type !== 'size'" class="column is-12">
            <Field
                v-model="model.variantName"
                :label="fieldLabel"
            />
        </div>

        <div v-else class="column is-12">
            <Field
                v-model="model.variantName"
                label="Size"
                is="select"
            >
                <template :key="size" v-for="size in ['XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL', '6XL']">
                    <option :value="size" >{{ size }}</option>
                </template>
            </Field>
        </div>

        <template v-if="model.type === 'color'">
            <div class="column is-12">
                <Field
                    v-model="model.color"
                    label="Color"
                    type="color"
                />
            </div>

            <div class="column is-12">
                <button
                    class="button is-primary"
                    @click.prevent="() => ($refs.fileInput as HTMLElement).click()"
                >
                    <span class="icon is-small">
                        <Icon :icon="mdiUpload" />
                    </span>
                    <span>
                        Upload Image
                    </span>
                </button>
            </div>
        </template>
    </div>

    <hr>

    <div class="is-flex is-justify-content-flex-end">
        <div class="buttons">
            <button
                v-if="!modelIsNew"
                class="button is-danger is-outlined"
                @click.prevent="() => emit('delete')"
            >
                <span class="icon is-small">
                    <Icon :icon="mdiTrashCanOutline" />
                </span>
                <span>
                    Delete
                </span>
            </button>
            <button
                class="button is-primary"
                @click.prevent="() => emit('save', model)"
            >
                <span class="icon is-small">
                    <Icon :icon="mdiFloppy" />
                </span>
                <span>
                    Save
                </span>
            </button>
        </div>
    </div>
</template>

<script setup lang="ts">
import {computed, watch,} from 'vue';
import type { ItemVariant } from '@/Types/ItemVariant';
import Field from '../common/Field.vue';
import { mdiTrashCanOutline, mdiFloppy, mdiUpload } from '@mdi/js';
import { ref } from 'vue';
import Icon from '../Icon.vue';

const props = defineProps<{
    modelValue: ItemVariant;
}>();

const model = ref<ItemVariant>(props.modelValue);

watch(() => props.modelValue, (newVal) => {
    model.value = props.modelValue;
});

const modelIsNew = computed(() => {
    return (props.modelValue?.id ?? 0) === 0;
});

const fieldLabel = computed(() => {
    switch (model.value.type) {
        case 'color':
            return 'Color Name';
        default:
            return 'Option Name';
    }
});

const emit = defineEmits<{
    (e: 'update:modelValue', value: ItemVariant): void;
    (e: 'save', value: ItemVariant): void;
    (e: 'delete'): void;
}>();

</script>

<template>
    <div class="box is-height-fit">
        <PageHead title="Forgot Password" />

        <div class="box-title">
            Forgot Password
        </div>

        <div>
            <form @submit.prevent="handleSubmit" novalidate v-if="!sentReset">
                <Field placeholder="Email" type="email" v-model="form.email" :disabled="isLoading">
                    <template #iconLeft>
                        <Icon :icon="mdiEmail" />
                    </template>
                </Field>

                <div class="is-flex is-align-items-center is-justify-content-flex-end">
                    <div>
                        <button type="submit" class="button is-primary" :class="{'is-loading': isLoading}">
                            <span class="icon is-small">
                                <Icon :icon="mdiRefresh" />
                            </span>
                            <span>
                                Request Password Reset
                            </span>
                        </button>
                    </div>
                </div>
            </form>

            <div v-else>
                <p>
                    If an account with that email exists, we will send you a link to reset your password.
                </p>
                <div class="is-flex is-justify-content-flex-end">
                    <Link class="button is-primary" :href="route('login')">
                        <span class="icon is-small">
                            <Icon :icon="mdiArrowLeft" />
                        </span>
                        <span>
                            Back To Login
                        </span>
                    </Link>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import Field from '@/Components/common/Field.vue';
import Icon from '@/Components/Icon.vue';
import { mdiArrowLeft, mdiEmail, mdiRefresh } from '@mdi/js'
import { useForm } from '@inertiajs/vue3';
import { Link } from '@inertiajs/vue3';
import Base from '@/Layouts/Base.vue';
import Default from '@/Layouts/Default.vue';
import PageHead from '@/Components/PageHead.vue';

defineOptions({
    layout: [Base, Default],
})

const isLoading = ref(false);
const sentReset = ref(false);

const form = useForm({
    email: ''
});

function handleSubmit() {
    form.post(route('password.email'), {
        onStart: () => {
            isLoading.value = true;
        },

        onFinish: () => {
            isLoading.value = false;
        },

        onSuccess: () => {
            form.reset();
            sentReset.value = true;
        }
    })
}
</script>

<style lang="scss" scoped>
    @use 'bulma/sass/utilities/mixins';

    .box {
        max-width: 25rem;
        width: 100%;
    }

    @include mixins.mobile {
        .box {
            min-width: 100%;
            width: 100%;
        }
    }
</style>

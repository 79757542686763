<template>
    <div class="is-flex is-flex-direction-column">
        <article class="message is-info">
            <div class="message-body">
                Create categories here, add items to categories by creating or editing an item.<br>
                Categories appear on the shop page in the order they are entered.<br>
                Items without a category appear at the top of the shop.
            </div>
        </article>

        <div v-for="i in form.categories.length" class="block is-flex is-align-items-center has-gap-medium">
            <button class="button is-ghost has-text-danger px-1" @click="form.categories.splice(i - 1, 1)">
                <Icon :icon="mdiClose" />
            </button>
            <Field v-model="form.categories[i - 1]" placeholder="Category name" class="is-flex-grow-1" />
        </div>

        <div v-if="error !== ''" class="message is-danger is-flex-grow-1">
            <div class="message-body">{{ error }}</div>
        </div>

        <div class="is-flex is-align-items-center">
            <button class="button is-primary is-width-fit" @click="form.categories.push('')">Add Category</button>
            <button v-if="!isSaving" class="button is-primary is-width-fit ml-auto" @click="saveCategories">Save</button>
            <button v-else class="button is-primary is-width-fit ml-auto is-loading">Save</button>
        </div>
    </div>
</template>

<script setup lang="ts">
import { mdiClose } from '@mdi/js';
import Field from '../common/Field.vue';
import { useShopStore } from '@/Stores/ShopStore';
import { onMounted, ref } from 'vue';
import Icon from '../Icon.vue';
import { useForm } from '@inertiajs/vue3';

const props = defineProps({
    shopId: {
        type: Number,
        required: true
    }
});

const shop = useShopStore();
const isSaving = ref(false);
const error = ref<string>('');

const form = useForm({
    categories: [] as string[]
});

onMounted(async () => {
    await shop.initialize(props.shopId);
    form.categories = JSON.parse(JSON.stringify(shop.categories)) ?? [];
})

const emit = defineEmits<{
    (e: 'close'): void;
}>();

async function saveCategories() {
    const thisRoute = route('admin.shops.update_categories', { shop: shop.slug });
    isSaving.value = true;
    error.value = '';
    form.post(thisRoute, {
        onSuccess: async () => {
            await shop.refresh();
            emit('close');
        },
        onError: () => {
            isSaving.value = false;
        }
    });
    
}

</script>

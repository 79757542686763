<template>
    <Transition name="fade">
        <div v-if="props.open" class="modal is-active">
            <div @click="emit('close')" class="modal-background"></div>
            <div class="modal-content">
                <div class="card has-background-primary-05">
                    <div class="is-flex is-justify-content-space-between px-2 is-align-items-center">
                        <div class="card-header">
                            <p class="card-header-title">{{ props.title }}</p>
                        </div>
                        <div class="card-header">
                            <button class="delete is-medium" @click="emit('close')"></button>
                        </div>
                    </div>

                    <div class="card-content">
                        <slot v-on:close="emit('close')" />
                    </div>
                </div>
            </div>
        </div>
    </Transition>
</template>

<script setup lang="ts">
const props = withDefaults(
    defineProps<{
        open: boolean;
        title: string;
    }>(),
    {
        open: false,
        title: '',
    }
);

const emit = defineEmits<{
    (e: 'update:open', value: boolean): void;
    (e: 'close'): void;
}>();

</script>

<style scoped lang="scss">
.modal {
    z-index: 20;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.15s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>

import { defineStore } from "pinia";
import {loadStripe, Stripe} from "@stripe/stripe-js";
import {usePage} from "@inertiajs/vue3";

export const usePaymentStore = defineStore('payment', {
    state: () => ({
        stripe: null as (Stripe | null),
        paymentIntentSecret: null as (string | null),
    }),

    actions: {
        async initialize(force: boolean = false) {
            if (!force && this.stripe) return;
            const page = usePage();
            this.stripe = await loadStripe(page.props.services.stripe.publishable_key);
        }
    }
});

<template>
    <div class="box" v-if="totalSalesReport">
        <h1 class="box-title">Total Sales Report</h1>

        <div class="is-flex is-align-items-center is-justify-content-center has-gap-large block is-flex-wrap-wrap">
            <VisSingleContainer :data="profitMarginGraph" :width="75" :height="75" style="width: fit-content;">
                <VisDonut :arcWidth="10" :value="d => d"
                          :color="(d, i) => ['var(--bulma-primary-90)', 'var(--bulma-primary-35)'][i]" />
            </VisSingleContainer>
            <div class="is-size-2">
                <b>{{ ((totalSalesReport.profit / totalSalesReport.totalSales) * 100).toFixed(2) }}%</b> Profit
                Margin
            </div>
        </div>

        <div class="table-container block">
            <table class="table is-width-100">
                <thead class="has-background-primary-25">
                <tr>
                    <th>Report</th>
                    <th>Amount</th>
                </tr>
                </thead>
                <tbody class="has-background-primary-05">
                <tr v-for="(amount, report) in totalSalesReport" :key="report">
                    <td>{{ getPrettyFieldName(report) }}</td>
                    <td>{{ formatNumber(amount) }}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div v-else class="box">
        <div class="skeleton-block"></div>
    </div>
</template>
<script setup lang="ts">
import {VisDonut, VisSingleContainer} from "@unovis/vue";
import {computed, inject} from "vue";
import {Report, ReportInjection} from "@/Types/Report";

const props = defineProps<{
    totalSalesReport: Report['totalSalesReport'];
}>();

const profitMarginGraph = computed(() => {
    if (!props.totalSalesReport) {
        return;
    }

    return [
        props.totalSalesReport.profit,
        props.totalSalesReport.totalSales - props.totalSalesReport.profit
    ];
});

const {
    getPrettyFieldName,
    formatNumber
} = inject<ReportInjection>('shopReports');
</script>

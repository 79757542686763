<template>
    <label v-if="props.label" class="label">{{ props.label }}</label>
    <div class="field has-addons">
        <slot />
    </div>
</template>

<script setup lang="ts">
import {provide, ref, watch} from "vue";
import {ButtonGroupProvide} from "@/Components/common/ButtonGroup/index";

const props = defineProps<{
    label?: string;
    modelValue?: string | boolean;
}>();

const emit = defineEmits<{
    (e: 'update:modelValue', value: string|boolean): void;
}>();

const activeValue = ref<string|boolean>(props.modelValue);
watch(() => props.modelValue, (newVal) => {
    activeValue.value = newVal;
});

provide<ButtonGroupProvide>('buttonGroup', {
    activeValue,
    updateActiveValue: (newVal: string|boolean) => {
        emit('update:modelValue', newVal);
    }
});
</script>

import {App} from "vue";
import dayjs from "dayjs";
import DayjsTimezone from "dayjs/plugin/timezone";
import DayjsUtc from "dayjs/plugin/utc";
import Calendar from "dayjs/plugin/calendar";
import RelativeTime from "dayjs/plugin/relativeTime";
import AdvancedFormat from "dayjs/plugin/advancedFormat";

export const DayJsPlugin = {
    install(app: App, _options: any) {
        const DayJs = dayjs;

        DayJs.extend(DayjsTimezone);
        DayJs.extend(DayjsUtc);
        DayJs.extend(Calendar);
        DayJs.extend(AdvancedFormat);
        DayJs.extend(RelativeTime);

        app.config.globalProperties.$dayjs = DayJs;
    }
}
